import { Box, Modal } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  background: '#111617DB',
  color: 'white',
  p: 4,
};

export default function BasicModal({handleClose, isOpen}) {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={'center'}>
            YOU SHOULD BE AUTHORIZED USER <br/>
            <small>Please log in using one of the following methods</small>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} textAlign={'center'}>
            <Button variant={'contained'} className={'sign-btn'} sx={{bgcolor: '#0dbcfe'}}>Sign via steam</Button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}