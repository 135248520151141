export const Background = () => {
  return (
    <div style={{position: 'fixed', zIndex: -2, top: 0, width: '100%'}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          margin: "auto",
          background: "rgb(11, 19, 29)",
          display: "block",
          zIndex: 1,
          position: "relative",
          shapeRendering: "auto"
        }}
        width={"100%"}
        height={"100%"}
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 1696 927"
      >
        <g transform="translate(848,463.5) scale(1,1) translate(-848,-463.5)">
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "\n  @keyframes ld-speed-dash {\n    0% { transform: translate(0,-2467.0475434455443px); }\n    100% { transform: translate(0,2467.0475434455443px); }\n  }\n  .ld.ld-speed-dash {\n    animation: ld-speed-dash 33.333333333333336s linear infinite;\n  }\n"
            }}
          />
          <g transform="translate(848,463.5)">
            <g style={{ transform: "rotate(54deg)" }}>
              <line
                x1="183.67102220240696"
                x2="183.67102220240696"
                y1={0}
                y2="-314.93832073879776"
                stroke="#ffffff"
                strokeWidth="1.029392869525957"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-4.347804260381446s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-406.265727816672"
                x2="-406.265727816672"
                y1={0}
                y2="-313.4293365980616"
                stroke="#ffffff"
                strokeWidth="2.3782776061857214"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-17.538532305443326s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-452.69579561597436"
                x2="-452.69579561597436"
                y1={0}
                y2="-311.6624523616613"
                stroke="#ffffff"
                strokeWidth="4.208229409059514"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-26.376337360159454s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-181.55600620323662"
                x2="-181.55600620323662"
                y1={0}
                y2="-469.5877739147744"
                stroke="#ffffff"
                strokeWidth="0.10582492558046881"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-25.118534042237346s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-138.45727377731893"
                x2="-138.45727377731893"
                y1={0}
                y2="-492.0701174673467"
                stroke="#13c6f9"
                strokeWidth="6.960611344104468"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-15.278545358812996s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-813.8316169207594"
                x2="-813.8316169207594"
                y1={0}
                y2="-177.10410731771915"
                stroke="#13c6f9"
                strokeWidth="2.009753866735443"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-21.932847995785508s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-127.28436273477878"
                x2="-127.28436273477878"
                y1={0}
                y2="-429.9156241180125"
                stroke="#13c6f9"
                strokeWidth="5.985169647839402"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-22.994968029353956s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-202.06218453389906"
                x2="-202.06218453389906"
                y1={0}
                y2="-360.07917292661455"
                stroke="#ffffff"
                strokeWidth="1.3631045013518566"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-17.908518254718118s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-744.5356818923276"
                x2="-744.5356818923276"
                y1={0}
                y2="-528.7271138522312"
                stroke="#13c6f9"
                strokeWidth="3.4993072080274317"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-25.206155541852763s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-551.2535751211317"
                x2="-551.2535751211317"
                y1={0}
                y2="-191.82056116703248"
                stroke="#13c6f9"
                strokeWidth="8.014411814077965"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-8.391463479409897s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-331.89659450908243"
                x2="-331.89659450908243"
                y1={0}
                y2="-424.73960210932273"
                stroke="#ffffff"
                strokeWidth="2.974935949520414"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-8.177465608310268s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="778.549470346208"
                x2="778.549470346208"
                y1={0}
                y2="-320.3996858678489"
                stroke="#ffffff"
                strokeWidth="4.053546037808625"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-11.030918802518986s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="775.2659120687762"
                x2="775.2659120687762"
                y1={0}
                y2="-518.8814556021372"
                stroke="#ffffff"
                strokeWidth="0.5388194992929343"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-19.21425689294416s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="501.90450695287456"
                x2="501.90450695287456"
                y1={0}
                y2="-487.00672346738827"
                stroke="#13c6f9"
                strokeWidth="6.677002985392665"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-27.66188426248583s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="296.2806802645471"
                x2="296.2806802645471"
                y1={0}
                y2="-206.27041548331974"
                stroke="#13c6f9"
                strokeWidth="6.740985848441041"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-25.38351725424146s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="91.90847783304521"
                x2="91.90847783304521"
                y1={0}
                y2="-455.95866678806067"
                stroke="#13c6f9"
                strokeWidth="4.002314956645076"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-19.837127813676258s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-567.9573563217772"
                x2="-567.9573563217772"
                y1={0}
                y2="-183.40707558622114"
                stroke="#13c6f9"
                strokeWidth="6.658406705722822"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-11.569916576505243s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="69.22512404260458"
                x2="69.22512404260458"
                y1={0}
                y2="-277.81713670011794"
                stroke="#ffffff"
                strokeWidth="0.6897768362912192"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-19.527707480790966s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-219.6309134778047"
                x2="-219.6309134778047"
                y1={0}
                y2="-185.73687019898787"
                stroke="#13c6f9"
                strokeWidth="4.034691792123094"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-28.210798724008498s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="369.71959937511195"
                x2="369.71959937511195"
                y1={0}
                y2="-465.1513675729169"
                stroke="#ffffff"
                strokeWidth="4.216941492367069"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-4.0611100843280346s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="612.2664320232659"
                x2="612.2664320232659"
                y1={0}
                y2="-302.26325091593935"
                stroke="#13c6f9"
                strokeWidth="4.892078733076057"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-17.734560362609223s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-45.66881718617205"
                x2="-45.66881718617205"
                y1={0}
                y2="-208.13926844048277"
                stroke="#13c6f9"
                strokeWidth="0.2617995128493672"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-11.138627552080942s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-483.1611893703525"
                x2="-483.1611893703525"
                y1={0}
                y2="-241.5074810031628"
                stroke="#13c6f9"
                strokeWidth="4.338791055538628"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-16.220219632079996s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="743.4912570323328"
                x2="743.4912570323328"
                y1={0}
                y2="-257.77256856210346"
                stroke="#13c6f9"
                strokeWidth="5.973903351962694"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-24.490672301405166s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="74.03285535162526"
                x2="74.03285535162526"
                y1={0}
                y2="-304.4143358426853"
                stroke="#ffffff"
                strokeWidth="0.09555572665067506"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-10.806543267976394s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="119.52307338723608"
                x2="119.52307338723608"
                y1={0}
                y2="-527.5303405396432"
                stroke="#ffffff"
                strokeWidth="5.593867681776347"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-25.32767783026863s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="182.47159552313366"
                x2="182.47159552313366"
                y1={0}
                y2="-355.4056430891022"
                stroke="#13c6f9"
                strokeWidth="0.4725270503532392"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-20.55363937157988s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-399.9312001171253"
                x2="-399.9312001171253"
                y1={0}
                y2="-365.6011675557065"
                stroke="#13c6f9"
                strokeWidth="5.380613407244678"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-3.144076583532189s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="451.276114050064"
                x2="451.276114050064"
                y1={0}
                y2="-332.743163809099"
                stroke="#ffffff"
                strokeWidth="0.4032928156300597"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-8.64664143716214s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="1.0284832290433599"
                x2="1.0284832290433599"
                y1={0}
                y2="-448.02790706229587"
                stroke="#13c6f9"
                strokeWidth="6.853607396812089"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-25.258658209878917s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-277.1446447304577"
                x2="-277.1446447304577"
                y1={0}
                y2="-173.36048044516426"
                stroke="#13c6f9"
                strokeWidth="4.9205543012730075"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-26.27267142787239s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-319.96709969718904"
                x2="-319.96709969718904"
                y1={0}
                y2="-251.98205111006524"
                stroke="#13c6f9"
                strokeWidth="5.1608025061956955"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-27.325066669646286s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="195.414202875449"
                x2="195.414202875449"
                y1={0}
                y2="-295.2060597403888"
                stroke="#13c6f9"
                strokeWidth="6.325266486646054"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-1.7408564947264171s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-193.91524244644776"
                x2="-193.91524244644776"
                y1={0}
                y2="-309.1178762517556"
                stroke="#13c6f9"
                strokeWidth="6.684628066701638"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-20.958050925621936s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-5.450403278740076"
                x2="-5.450403278740076"
                y1={0}
                y2="-261.35415609880886"
                stroke="#13c6f9"
                strokeWidth="4.647709604439295"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-30.297583617504596s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-3.5028764672935537"
                x2="-3.5028764672935537"
                y1={0}
                y2="-289.495276893084"
                stroke="#ffffff"
                strokeWidth="4.642201145330622"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-6.01986831503499s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-564.1239776233974"
                x2="-564.1239776233974"
                y1={0}
                y2="-202.61041724130388"
                stroke="#ffffff"
                strokeWidth="2.9340260853721354"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-26.23480186675513s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-107.26272660236174"
                x2="-107.26272660236174"
                y1={0}
                y2="-417.46088618733927"
                stroke="#13c6f9"
                strokeWidth="2.6659201239395993"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-25.03395388654597s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="641.9008759631306"
                x2="641.9008759631306"
                y1={0}
                y2="-174.87369786964157"
                stroke="#13c6f9"
                strokeWidth="6.750361169579456"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-26.827879762892707s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="145.68331678099688"
                x2="145.68331678099688"
                y1={0}
                y2="-445.3142865513505"
                stroke="#13c6f9"
                strokeWidth="7.653235792625631"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-31.556174274658883s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="69.11836412201569"
                x2="69.11836412201569"
                y1={0}
                y2="-405.45343095734955"
                stroke="#13c6f9"
                strokeWidth="1.0835840135352182"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-25.624086792541892s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-93.73435375206792"
                x2="-93.73435375206792"
                y1={0}
                y2="-342.699461747302"
                stroke="#ffffff"
                strokeWidth="0.6914779544164609"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-29.66983081907629s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="317.8857533806701"
                x2="317.8857533806701"
                y1={0}
                y2="-178.32264932765756"
                stroke="#ffffff"
                strokeWidth="2.7424670507888456"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-11.170906970342806s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="232.43903712653164"
                x2="232.43903712653164"
                y1={0}
                y2="-502.7501936604325"
                stroke="#ffffff"
                strokeWidth="5.553319090073788"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-20.885331083384767s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="98.08683362492155"
                x2="98.08683362492155"
                y1={0}
                y2="-400.322243371146"
                stroke="#13c6f9"
                strokeWidth="5.4215671801171474"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-5.839655829302896s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-763.2336357086386"
                x2="-763.2336357086386"
                y1={0}
                y2="-492.50009623515484"
                stroke="#ffffff"
                strokeWidth="7.538444519352958"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-26.141153379902196s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="65.09078511342986"
                x2="65.09078511342986"
                y1={0}
                y2="-299.464249120471"
                stroke="#ffffff"
                strokeWidth="5.758650562304105"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-4.687946291725583s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-124.19937297157614"
                x2="-124.19937297157614"
                y1={0}
                y2="-255.42205179917784"
                stroke="#13c6f9"
                strokeWidth="3.077607469075158"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-21.652657842363862s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="736.577671558657"
                x2="736.577671558657"
                y1={0}
                y2="-315.2760568621517"
                stroke="#ffffff"
                strokeWidth="1.5935396222607592"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-32.77554233229048s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="161.3791348562151"
                x2="161.3791348562151"
                y1={0}
                y2="-200.44890858845966"
                stroke="#ffffff"
                strokeWidth="3.124923980592195"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-0.16645850187676134s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="470.6248879687703"
                x2="470.6248879687703"
                y1={0}
                y2="-406.74139796236017"
                stroke="#ffffff"
                strokeWidth="2.232893750682092"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-32.18317556450724s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-225.21000729081624"
                x2="-225.21000729081624"
                y1={0}
                y2="-372.5571542667835"
                stroke="#ffffff"
                strokeWidth="2.0217781922921603"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-14.078215152307369s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="819.9238487936722"
                x2="819.9238487936722"
                y1={0}
                y2="-338.5145292411628"
                stroke="#13c6f9"
                strokeWidth="5.880184836343139"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-16.635234516652787s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="314.2933955217085"
                x2="314.2933955217085"
                y1={0}
                y2="-208.12708720443558"
                stroke="#13c6f9"
                strokeWidth="5.276770566052547"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-23.030011351090153s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-131.5881205065925"
                x2="-131.5881205065925"
                y1={0}
                y2="-239.5011415494489"
                stroke="#13c6f9"
                strokeWidth="1.0597104649019315"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-23.430497212640166s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-552.0846929508638"
                x2="-552.0846929508638"
                y1={0}
                y2="-333.4446978583324"
                stroke="#13c6f9"
                strokeWidth="2.9899083891789577"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-2.6156635271726847s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="194.15126426913966"
                x2="194.15126426913966"
                y1={0}
                y2="-470.04541840452055"
                stroke="#ffffff"
                strokeWidth="5.3505877755961055"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-11.213568850593733s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="628.8039431950001"
                x2="628.8039431950001"
                y1={0}
                y2="-166.74898582971576"
                stroke="#13c6f9"
                strokeWidth="7.392801545639728"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-29.595360689783657s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="745.0462962986435"
                x2="745.0462962986435"
                y1={0}
                y2="-378.6205902056663"
                stroke="#13c6f9"
                strokeWidth="3.2296206071813125"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-5.950999409155052s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="72.85151796324969"
                x2="72.85151796324969"
                y1={0}
                y2="-316.83367619725936"
                stroke="#13c6f9"
                strokeWidth="1.9935410392401294"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-29.78987678791425s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-763.498094663934"
                x2="-763.498094663934"
                y1={0}
                y2="-217.5466055326273"
                stroke="#13c6f9"
                strokeWidth="3.432708835219339"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-26.413701093136375s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="203.44574291086116"
                x2="203.44574291086116"
                y1={0}
                y2="-260.8294958376722"
                stroke="#13c6f9"
                strokeWidth="3.6108562661918735"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-13.93721674544748s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-423.74804457011544"
                x2="-423.74804457011544"
                y1={0}
                y2="-418.28709321279314"
                stroke="#ffffff"
                strokeWidth="6.253836491316756"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-5.192951585071796s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-215.72055241876313"
                x2="-215.72055241876313"
                y1={0}
                y2="-198.29715755686095"
                stroke="#ffffff"
                strokeWidth="6.668172306800277"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-27.511141759876892s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-744.0991608095245"
                x2="-744.0991608095245"
                y1={0}
                y2="-255.81003520139927"
                stroke="#ffffff"
                strokeWidth="5.02409957578238"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-0.054451299258710215s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-377.32488124408115"
                x2="-377.32488124408115"
                y1={0}
                y2="-243.63595893517862"
                stroke="#13c6f9"
                strokeWidth="8.093189915016424"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-12.015313599346008s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="717.1988914298942"
                x2="717.1988914298942"
                y1={0}
                y2="-348.136476217093"
                stroke="#13c6f9"
                strokeWidth="6.777812433031297"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-30.61004597383145s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="168.80002948830438"
                x2="168.80002948830438"
                y1={0}
                y2="-368.0088592501616"
                stroke="#13c6f9"
                strokeWidth="7.838567511565637"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-12.96743153360383s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-204.79636043872353"
                x2="-204.79636043872353"
                y1={0}
                y2="-445.6701825192878"
                stroke="#13c6f9"
                strokeWidth="5.525806911820306"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-24.890071126523846s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-472.65670465804305"
                x2="-472.65670465804305"
                y1={0}
                y2="-508.5214834063694"
                stroke="#13c6f9"
                strokeWidth="2.643242732303978"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-6.709111347117713s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="204.09063894335043"
                x2="204.09063894335043"
                y1={0}
                y2="-452.4658715707618"
                stroke="#13c6f9"
                strokeWidth="0.3615606939341604"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-25.214377077683007s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-274.00443769751786"
                x2="-274.00443769751786"
                y1={0}
                y2="-525.4110047267636"
                stroke="#ffffff"
                strokeWidth="7.33700137036424"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-19.40482267568982s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-385.3875832836206"
                x2="-385.3875832836206"
                y1={0}
                y2="-303.60811894994583"
                stroke="#ffffff"
                strokeWidth="5.807058753193581"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-23.170779858683225s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-160.81511764360752"
                x2="-160.81511764360752"
                y1={0}
                y2="-252.64838821251274"
                stroke="#13c6f9"
                strokeWidth="3.8154508904820585"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-16.745220794983286s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="577.2441349953208"
                x2="577.2441349953208"
                y1={0}
                y2="-324.893550893488"
                stroke="#ffffff"
                strokeWidth="7.760327729423261"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-24.771494219610897s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="328.5241542935741"
                x2="328.5241542935741"
                y1={0}
                y2="-337.8624228501077"
                stroke="#13c6f9"
                strokeWidth="5.64726171732086"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-26.009012654868332s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-281.9382081810147"
                x2="-281.9382081810147"
                y1={0}
                y2="-232.00118573904751"
                stroke="#ffffff"
                strokeWidth="7.238051385305835"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-14.345949256444984s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-157.13554713922355"
                x2="-157.13554713922355"
                y1={0}
                y2="-379.4812909668243"
                stroke="#ffffff"
                strokeWidth="3.4850247555375957"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-16.220397960532267s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="429.5851271674755"
                x2="429.5851271674755"
                y1={0}
                y2="-153.33878014841952"
                stroke="#13c6f9"
                strokeWidth="7.765959039618755"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-0.5464738967441374s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-93.36943632661882"
                x2="-93.36943632661882"
                y1={0}
                y2="-220.15288816213467"
                stroke="#13c6f9"
                strokeWidth="0.7184367987602998"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-1.845702666923684s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="1.2190956184917923"
                x2="1.2190956184917923"
                y1={0}
                y2="-432.444217833881"
                stroke="#13c6f9"
                strokeWidth="2.082157422824707"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-30.53057802158085s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-159.69772461592552"
                x2="-159.69772461592552"
                y1={0}
                y2="-284.19463687432176"
                stroke="#ffffff"
                strokeWidth="1.6526786673071223"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-19.960234102226657s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="716.5111797038729"
                x2="716.5111797038729"
                y1={0}
                y2="-343.5694250757437"
                stroke="#13c6f9"
                strokeWidth="2.2534356813168928"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-1.1881364688983191s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-245.08009536250563"
                x2="-245.08009536250563"
                y1={0}
                y2="-354.8266810714309"
                stroke="#ffffff"
                strokeWidth="8.468488514314457"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-15.50343989563443s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="570.9403515203957"
                x2="570.9403515203957"
                y1={0}
                y2="-502.79422823199184"
                stroke="#13c6f9"
                strokeWidth="4.917471416921298"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-21.79784048489116s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-320.76018709262434"
                x2="-320.76018709262434"
                y1={0}
                y2="-298.7667924463817"
                stroke="#ffffff"
                strokeWidth="0.5959950711529669"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-1.2335536886396437s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="395.8277160113797"
                x2="395.8277160113797"
                y1={0}
                y2="-161.50286891511652"
                stroke="#ffffff"
                strokeWidth="2.2061901802947563"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-11.566327023266439s",
                  animationDuration: "33.333333333333336s"
                }}
              />
              <line
                x1="-585.097165609914"
                x2="-585.097165609914"
                y1={0}
                y2="-460.0503053131067"
                stroke="#13c6f9"
                strokeWidth="1.3161709004307112"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-20.476483708734524s",
                  animationDuration: "16.666666666666668s"
                }}
              />
              <line
                x1="-792.2746439420197"
                x2="-792.2746439420197"
                y1={0}
                y2="-306.67898256214914"
                stroke="#ffffff"
                strokeWidth="1.651675314976481"
                strokeLinecap="round"
                className="ld ld-speed-dash"
                style={{
                  animationDelay: "-13.98797285190659s",
                  animationDuration: "16.666666666666668s"
                }}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  )

}