import { Box, styled } from "@mui/material";

const GameIconStyled = styled(Box)({
  background: '#000000',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '5px',
  'img.gameIcon__bg' : {
    width: '100%',
    height: '100%',
    opacity: 0.5,
    transition: 'all 0.3s',
    position: 'relative',
    display: 'block'
  },
  'img.gameIcon__bg:hover': {
    transition: 'all 0.5',
    opacity: 1,
    transform: 'scale(1.4)',
  },
  'img.gameIcon__icon': {
    position: 'absolute',
    bottom: 0,
    right: 0,
    maxWidth: '300px',
  }
});

export const GameIcon = ({icon, background, lobbies, teams, players, onClick}) => {
  return (
    <GameIconStyled onClick={onClick}>
      <Box className="gameIcon__stat" textAlign={'left'} color="#f4f4f4ab" margin="15px" zIndex={1} position="absolute">
        <h3>Lobbies: <strong style={{color: "#0dbcfe"}}>{lobbies}+</strong></h3>
        <h3>Teams: <strong style={{color: "#0dbcfe"}}>{teams}+</strong></h3>
        <h3>Players: <strong style={{color: "#0dbcfe"}}>{players}+</strong></h3>
      </Box>
      <img className="gameIcon__icon" src={icon} alt=""/>
      <img className="gameIcon__bg" src={background} alt=""/>
    </GameIconStyled>
  )
}