import { Box } from "@mui/material";

export const FaqPage = () => {
 return (
   <Box bgcolor="#12161bf7" padding={"50px"} className="main container-fluid" color={"white"} textAlign={'left'}>
     <div data-v-ae34ca0e="" className="container">
       <div data-v-ae34ca0e="" className="row">
         <div data-v-ae34ca0e="" className="col-md-12 col-xl-8 offset-xl-2">
           <div data-v-ae34ca0e="" className="row">
             <div data-v-ae34ca0e="" className="col-md-12">
               <Box>
                 <div data-v-ae34ca0e="" className="row">
                   <div data-v-ae34ca0e="" className="col">
                     <h1
                       data-v-ae34ca0e=""
                       className="text-center title anim-fadein-1 mb-1"
                       style={{ color: 'white'}}
                     >
                       {/* eslint-disable-next-line no-undef */}
                       {SITE_CONFIG.siteName}{" "}
                       <span data-v-ae34ca0e="" className="mythic-header">
                    <strong style={{color: "#0dbcfe", fontWeight: 900}}>FAQ</strong>
                  </span>
                     </h1>{" "}
                     <p
                       data-v-ae34ca0e=""
                       className="tagline anim-fadein-2 text-center mb-4"
                       style={{ color: 'white'}}
                     >
                       OUR MOST FREQUENTLY ASKED QUESTIONS
                     </p>
                   </div>
                 </div>{" "}
               </Box>
               <Box textAlign="left" color="#0dbcfe" margin={'100px'}>
                 <div data-v-ae34ca0e="" className="row mythic-bg rules-item mb-4">
                   <div data-v-ae34ca0e="" className="col-md-12">
                     <h2 data-v-ae34ca0e="" style={{ color: 'white'}} className="faq-item-header mb-3">
                       CONTENTS
                     </h2>
                   </div>{" "}
                   <Box>
                     <div data-v-ae34ca0e="" className="faq-contents-col col-md-12">
                       <ol data-v-ae34ca0e="" className="faq-contents-list">
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#1">
                             NEW AND RETURNING PLAYERS
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#2">
                             CONNECTING THE CORRECT DISCORD ACCOUNT
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#3">
                             DO I HAVE TO USE DISCORD?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#4">
                             DO I HAVE TO PAY TO USE THIS SERVICE?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#5">
                             DO I NEED FACEIT PREMIUM?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#6">
                             DO I NEED TO SUBSCRIBE TO EVERY DIVISION?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#7">
                             DO WE GAIN AND LOSE ELO?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#8">
                             HOW DOES TEAM AND MAP SELECTION WORK?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#9">
                             THE SERVER IS SAYING MY ANTI-CHEAT IS NOT ENABLED?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#10">
                             HOW DO I CONTACT AN ADMIN FOR HELP?{" "}
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#11">
                             WHAT IS AN OUTAGE MAP AND HOW DO I FIND MINE?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#12">
                             WHEN WILL I GET MY PRIZE?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#13">
                             HOW DO YOU QUALIFY FOR FPL CHALLENGER?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#14">
                             CONNECTION FAILED AFTER 30 RETRIES?
                           </a>
                         </li>{" "}
                         <li data-v-ae34ca0e="">
                           <a data-v-ae34ca0e="" href="#15">
                             STEAM ID NOW ALLOWED / MUST CONNECT FROM A LOBBY
                           </a>
                         </li>
                       </ol>
                     </div>
                   </Box>
                 </div>
               </Box>
             </div>{" "}
             <Box textAlign={'left'} margin={'50px'} color={'white'}>
               <div data-v-ae34ca0e="" className="col-md-12">
                 <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                   <div data-v-ae34ca0e="" className="col-md-12">
                     <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                       <a
                         data-v-ae34ca0e=""
                         id={1}
                         href="#1"
                         target="_self"
                         className=""
                       >
                         1.
                       </a>
                       NEW AND RETURNING PLAYERS
                     </h2>{" "}
                     <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                     <div data-v-ae34ca0e="" className="faq-item-sub-header mb-2">
                       <a
                         data-v-ae34ca0e=""
                         id="1i"
                         href="#1i"
                         target="_self"
                         className=""
                       >
                         I.
                       </a>
                       NEW PLAYERS
                     </div>{" "}
                     <p data-v-ae34ca0e="">
                       In order to unlock the ability to queue and use Mythic League
                       features first your account must be successfully authorized
                       with our system.
                     </p>{" "}
                     <p data-v-ae34ca0e="">
                       Before completing the following setup it is recommended that
                       you ensure that your
                       <a
                         data-v-ae34ca0e=""
                         href="https://faceit.com"
                         rel="noopener"
                         target="_blank"
                         className=""
                       >
                         FACEIT account
                       </a>
                       and your
                       <a
                         data-v-ae34ca0e=""
                         href="https://discord.gg"
                         rel="noopener"
                         target="_blank"
                         className=""
                       >
                         Discord account
                       </a>
                       are logged in on your main web browser. This will reduce the
                       chance of error during the setup process.
                     </p>{" "}
                     <div data-v-ae34ca0e="" className="faq-item-sub-header mb-2">
                       <a
                         data-v-ae34ca0e=""
                         id="1ii"
                         href="#1ii"
                         target="_self"
                         className=""
                       >
                         II.
                       </a>
                       ACCOUNT SETUP / AUTHORIZATION
                     </div>{" "}
                     <ol data-v-ae34ca0e="">
                       <li data-v-ae34ca0e="">
                         Make sure that you reside in one of our
                         <a
                           data-v-ae34ca0e=""
                           href="/rules#8"
                           rel="noopener"
                           target="_blank"
                           className=""
                         >
                           allowed locations
                         </a>
                         .
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         Make sure that you meet the
                         <a
                           data-v-ae34ca0e=""
                           href="/rules#1i"
                           rel="noopener"
                           target="_blank"
                           className=""
                         >
                           minimum match limit
                         </a>
                         for the division that matches your ELO.
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         Join our
                         <a
                           data-v-ae34ca0e=""
                           href="https://discord.mythicleague.com"
                           rel="noopener"
                           target="_blank"
                           className=""
                         >
                           Discord Server
                         </a>
                         .
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         Join the division that matches your ELO (Bronze, Silver,
                         Gold)
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         <a
                           data-v-ae34ca0e=""
                           href="https://ml-face.it/subscribe"
                           rel="noopener"
                           target="_blank"
                           className=""
                         >
                           Subscribe
                         </a>
                         to Mythic League.
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         <a
                           data-v-ae34ca0e=""
                           href="https://ml-face.it/auth"
                           rel="noopener"
                           target="_blank"
                           className=""
                         >
                           Authorize
                         </a>
                         your account to our service and complete registration by
                         reviewing and agreeing to the
                         <a
                           data-v-ae34ca0e=""
                           href="/rules"
                           className=""
                           target="_self"
                         >
                           Mythic League Rules
                         </a>
                         .
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         Click the "Sync Account" button and watch the screen for a
                         response in{" "}
                         <span data-v-ae34ca0e="" className="color:red">
                      RED
                    </span>{" "}
                         text. If successful, your Discord username will turn
                         <span data-v-ae34ca0e="" className="color:green">
                      GREEN
                    </span>
                         .
                       </li>
                     </ol>{" "}
                     <p data-v-ae34ca0e="">
                       If you receive an error message after clicking Sync Account
                       please follow that error message to the appropriate FAQ
                       section for resolution.
                     </p>{" "}
                     <div data-v-ae34ca0e="" className="faq-item-sub-header mb-2">
                       <a
                         data-v-ae34ca0e=""
                         id="1iii"
                         href="#1iii"
                         target="_self"
                         className=""
                       >
                         III.
                       </a>
                       HOW TO PLAY
                     </div>{" "}
                     <ol data-v-ae34ca0e="">
                       <li data-v-ae34ca0e="">
                         Load up FACEIT.com or the FACEIT standalone client.
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         Load up the FACEIT Anti-Cheat and make sure it is
                         up-to-date.
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         Join the AUTO-VOIP Discord channel in the Mythic League
                         Discord.
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         Press the Play button in your current division!
                       </li>
                     </ol>{" "}
                     <div data-v-ae34ca0e="" className="faq-item-sub-header mb-2">
                       <a
                         data-v-ae34ca0e=""
                         id="1iv"
                         href="#1iv"
                         target="_self"
                         className=""
                       >
                         IV.
                       </a>
                       RETURNING PLAYERS
                     </div>{" "}
                     <p data-v-ae34ca0e="">
                       If you were a previously subscribed and authorized player in
                       Mythic League you do not need to complete the Account Setup /
                       Authorization process a second time in most cases. Follow the
                       steps below:
                     </p>{" "}
                     <ol data-v-ae34ca0e="">
                       <li data-v-ae34ca0e="">
                         Navigate to your Mythic League profile either through our
                         <a
                           data-v-ae34ca0e=""
                           href="https://support.mythicleague.com/profile"
                           rel="noopener"
                           target="_blank"
                           className=""
                         >
                           Support Website
                         </a>
                         , Discord (!profile) or the Hub chat (!profile)
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         Check that your details and connections are still the same:
                         Discord account, FACEIT account and tradelink.
                       </li>{" "}
                       <li data-v-ae34ca0e="">
                         If all details are correct hit the Sync Account button and
                         wait for the response in red text. If successful, your
                         Discord username will turn
                         <span data-v-ae34ca0e="" className="color:green">
                      GREEN
                    </span>{" "}
                         and you are ready to play again.
                       </li>
                     </ol>{" "}
                     <p data-v-ae34ca0e="">
                       If you have changed Discord account you can either
                       re-authorize or swap your Discord on your profile page before
                       hitting Sync Account.
                     </p>{" "}
                     <p data-v-ae34ca0e="">
                       Stuck? Need help? Just
                       <span data-v-ae34ca0e="" className="color-role-support">
                    @Support
                  </span>{" "}
                       in the
                       <b data-v-ae34ca0e="" className="color-channel-discord">
                         #support
                       </b>{" "}
                       channel of our Discord servers and we'd be happy to help!
                     </p>
                   </div>
                 </div>
               </div>{" "}
             </Box>
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={2}
                       href="#2"
                       target="_self"
                       className=""
                     >
                       2.
                     </a>
                     CONNECTING THE CORRECT DISCORD ACCOUNT
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     Check that the Discord identifier (MyAccount#0001) found on
                     your
                     <a
                       data-v-ae34ca0e=""
                       href="/profile"
                       rel="noopener"
                       target="_blank"
                       className=""
                     >
                       Mythic League profile
                     </a>
                     matches the one that is found in the lower left of your
                     Discord client.
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     If these do not match, it means that you have a different
                     account signed into the browser as the client and as a result
                     the Account Setup / Authorization process may fail. In order
                     to resolve this, you will need to logout of the Discord
                     browser instance and login with the correct account (the one
                     that your Discord client is using). The steps to do this are:
                   </p>{" "}
                   <ol data-v-ae34ca0e="">
                     <li data-v-ae34ca0e="">
                       Visit
                       <a
                         data-v-ae34ca0e=""
                         href="https://discordapp.com/channels/@me"
                         rel="noopener"
                         target="_blank"
                         className=""
                       >
                         https://discordapp.com/channels/@me
                       </a>
                       .
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       Click the "User Settings" cog wheel in the lower left of the
                       screen.
                     </li>{" "}
                     <li data-v-ae34ca0e="">Click "Log Out".</li>{" "}
                     <li data-v-ae34ca0e="">
                       Now you can complete Account Setup / Authorization and will
                       be prompted to login to the correct Discord account during
                       this process.
                     </li>
                   </ol>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={3}
                       href="#3"
                       target="_self"
                       className=""
                     >
                       3.
                     </a>
                     DO I HAVE TO USE DISCORD?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     Yes. Discord is required in order to play in Mythic League.
                     You will be asked to link your Discord account with our system
                     during the authorization process before being able to queue.
                     Authorization:
                     <a
                       data-v-ae34ca0e=""
                       href="/https://ml-face.it/auth"
                       className=""
                       rel="noopener"
                       target="_blank"
                     >
                       ml-face.it/auth{" "}
                     </a>
                     .
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     Your permission to queue in each Division will be restricted
                     until your authorization is successful. (If successful, your
                     Discord username will turn
                     <span data-v-ae34ca0e="" className="color:green">
                    GREEN
                  </span>
                     ).
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     Users competing in the Mythic Diamond Division will be using
                     our private TeamSpeak server for their match communication
                     (details can be found{" "}
                     <a
                       data-v-ae34ca0e=""
                       href="/teamspeak"
                       className=""
                       target="_self"
                     >
                       here
                     </a>
                     ) but it is recommended to have your Discord linked in order
                     to use the{" "}
                     <b data-v-ae34ca0e="" className="color-channel-discord">
                       #support
                     </b>{" "}
                     channel as well as queue in the other divisions.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={4}
                       href="#4"
                       target="_self"
                       className=""
                     >
                       4.
                     </a>
                     DO I HAVE TO PAY TO USE THIS SERVICE?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     The Mythic League solo queue Divisions (Bronze, Silver, Gold,
                     Diamond) all require a Mythic League organization subscription
                     which starts at $6 per month.
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     The Mythic League Community experience is free to all users
                     but you can represent and support us directly with a profile
                     badge and other extras by purchasing a subscription.
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     Tournaments and Cups hosted by Mythic League will most
                     commonly be free to enter. Any events requiring a Mythic
                     League organization subscription will be clearly described as
                     such.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={5}
                       href="#5"
                       target="_self"
                       className=""
                     >
                       5.
                     </a>
                     DO I NEED FACEIT PREMIUM?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     No, you only require an active subscription to our hub
                     organizer, Mythic League.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={6}
                       href="#6"
                       target="_self"
                       className=""
                     >
                       6.
                     </a>
                     DO I NEED TO SUBSCRIBE TO EVERY DIVISION?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     No. You only need to subscribe to the organization. You will
                     gain hub access based upon your level to the correct division.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={7}
                       href="#7"
                       target="_self"
                       className=""
                     >
                       7.
                     </a>
                     DO WE GAIN AND LOSE ELO?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     Yes, ELO is enabled in Mythic League. You can earn and lose
                     ELO when playing solo in Mythic Bronze, Silver and Gold as
                     well as playing either solo or in a group in the Mythic League
                     Community hub.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={8}
                       href="#8"
                       target="_self"
                       className=""
                     >
                       8.
                     </a>
                     HOW DOES TEAM AND MAP SELECTION WORK?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     A captain is chosen by either ELO or Leaderboard Position, and
                     will take turns vetoing maps until a single map is left. In
                     regards for teams, players will take turns selecting players
                     in the format ABBAABBA.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={9}
                       href="#9"
                       target="_self"
                       className=""
                     >
                       9.
                     </a>
                     THE SERVER IS SAYING MY ANTI-CHEAT IS NOT ENABLED?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     If you connect to a server and are kicked with a reason
                     telling you that the anti-cheat is not enabled. Follow the
                     steps below:
                   </p>{" "}
                   <ul data-v-ae34ca0e="">
                     <li data-v-ae34ca0e="">
                       Ensure that you have the Anti-Cheat running.
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       If the Anti-Cheat is running and you receive this message,
                       quit the Anti-Cheat through the task manager and restart the
                       application.
                     </li>
                   </ul>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={10}
                       href="#10"
                       target="_self"
                       className=""
                     >
                       10.
                     </a>
                     HOW DO I CONTACT AN ADMIN FOR HELP?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     You can contact the Mythic League Support staff in the
                     following ways:
                   </p>{" "}
                   <ol data-v-ae34ca0e="">
                     <li data-v-ae34ca0e="">
                       If you are in a live game in any of our solo-queue
                       Divisions, navigate to the matchroom page and hit the
                       Contact Admin button.
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       If you are not in a live game and need assistance with
                       general support queries you can contact us by tagging the
                       <span data-v-ae34ca0e="" className="color-role-support">
                      @Support
                    </span>{" "}
                       role in our
                       <b data-v-ae34ca0e="" className="color-channel-discord">
                         #support
                       </b>{" "}
                       Discord channel and leave your question.
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       If you would like to report players for breaking rules
                       anywhere in Mythic League (in-game, Discord, chats) please
                       create a report
                       <a
                         data-v-ae34ca0e=""
                         href="https://support.mythicleague.com/reports/create"
                         rel="noopener"
                         target="_blank"
                         className=""
                       >
                         here
                       </a>
                       on our support website.
                     </li>
                   </ol>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={11}
                       href="#11"
                       target="_self"
                       className=""
                     >
                       11.
                     </a>
                     WHAT IS AN OUTAGE MAP AND HOW DO I FIND MINE?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     Most power and internet providers will provide an up-to-date
                     feed and/or interactive map of areas that are currently or
                     have been affected by a service disruption. You can often find
                     these on the provider's main website under a heading such as
                     “Outages”. Some providers will have a live-feed of updates on
                     an associated Twitter account.
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     Each company is different, some may require an account number
                     or other information about your residence to view outage
                     information, while others may provide a map that can be
                     searched.
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     Please provide your outage map as soon as possible so that we
                     can verify any issues you may have experienced.
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     <b data-v-ae34ca0e="">NOTE:</b> Outage maps are only relevant
                     for leaver bans.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={12}
                       href="#12"
                       target="_self"
                       className=""
                     >
                       12.
                     </a>
                     WHEN WILL I GET MY PRIZE?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     Prizes are sent out between two and four weeks following the
                     end of the monthly season (the monthly season consists of both
                     Week 1 &amp; 2 and Week 3 &amp; 4 leaderboards).
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     If you are placed for a prize, make sure that your tradelink
                     or PayPal address is set correctly on your
                     <a
                       data-v-ae34ca0e=""
                       href="/profile"
                       className=""
                       target="_self"
                     >
                       profile
                     </a>
                     and ensure that you are able to accept trades from all users.
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     Estimated delivery dates for each season as well as other
                     updated information can be found in the #prizes channel on the
                     Mythic League Discord.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={13}
                       href="#13"
                       target="_self"
                       className=""
                     >
                       13.
                     </a>
                     HOW DO YOU QUALIFY FOR FPL CHALLENGER?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     There are two ways to qualify for the NA FPL Challenger
                     division in Mythic League:
                   </p>{" "}
                   <ol data-v-ae34ca0e="">
                     <li data-v-ae34ca0e="">
                       The 45 top placing players of each Diamond monthly season
                       will be rewarded with invitations to the NA FPL-C
                       qualifiers.
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       The top 5 placing players of each Gold monthly season will
                       be rewarded with invitations to the NA FPL-C qualifiers AND
                       a trial invitation to the Diamond division.
                     </li>
                   </ol>{" "}
                   <p data-v-ae34ca0e="">
                     The NA FPL-C qualifier event will be announced in the
                     following month and all players who qualified will be invited
                     directly by FACEIT. Players will play in a weekend long event
                     which will see the top 5 invited into the FPL Challenger
                     League.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={14}
                       href="#14"
                       target="_self"
                       className=""
                     >
                       14.
                     </a>
                     CONNECTION FAILED AFTER 30 RETRIES?
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     Occasionally players may receive an error that prints in the
                     user’s console as “Retrying public…” repeatedly until finally
                     failing to connect. This error can be easily resolved by
                     following these steps:
                   </p>{" "}
                   <ol data-v-ae34ca0e="">
                     <li data-v-ae34ca0e="">Exit your game completely</li>{" "}
                     <li data-v-ae34ca0e="">
                       Navigate to Steam and select Counter-Strike: Global
                       Offensive
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       Right-click and select Properties from the context menu
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       Under the General tab, select Launch Options
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       <b data-v-ae34ca0e="">
                         Create an additional launch option string as "+clientport
                         2700X” with X representing a new value that is not 5. e.g.
                         6/7/8/9
                       </b>
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       Relaunch your game and connect to the server
                     </li>
                   </ol>{" "}
                   <p data-v-ae34ca0e="">
                     Further assistance may be found in the
                     <b data-v-ae34ca0e="" className="color-channel-discord">
                       #support
                     </b>{" "}
                     channel of the Mythic League Discord.
                   </p>
                 </div>
               </div>
             </div>{" "}
             <div data-v-ae34ca0e="" className="col-md-12">
               <div data-v-ae34ca0e="" className="row faq-item mythic-bg mb-4">
                 <div data-v-ae34ca0e="" className="col-md-12">
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <h2 data-v-ae34ca0e="" className="faq-item-header mb-3">
                     <a
                       data-v-ae34ca0e=""
                       id={15}
                       href="#15"
                       target="_self"
                       className=""
                     >
                       15.
                     </a>
                     STEAM ID NOT ALLOWED / MUST CONNECT FROM A LOBBY
                   </h2>{" "}
                   <hr style={{color: '#0dbcfe', background: '#0dbcfe', height: '5px', border: 'none'}}/>
                   <p data-v-ae34ca0e="">
                     Occasionally players may receive either of these two errors
                     when attempting to join a game server. These are both very
                     simple fixes and should not require extended connection time
                     from an admin.
                   </p>{" "}
                   <p data-v-ae34ca0e="">
                     NOTE: We DO NOT recommend ever using the “Go to Server” button
                     on the match page.
                   </p>{" "}
                   <div data-v-ae34ca0e="" className="rules-item-sub-header mb-2">
                     <a
                       data-v-ae34ca0e=""
                       id="15i"
                       href="#15i"
                       target="_self"
                       className=""
                     >
                       I.
                     </a>
                     STEAM ID NOT ALLOWED
                   </div>{" "}
                   <p data-v-ae34ca0e="">In order of priority:</p>{" "}
                   <ol data-v-ae34ca0e="">
                     <li data-v-ae34ca0e="">
                       Refresh the lobby page and ensure that you have copied the
                       correct and current IP address for the game before joining
                       through the game console.
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       Exit CS:GO and relaunch the game before joining with the
                       correct IP address through the game console.
                     </li>{" "}
                     <li data-v-ae34ca0e="">
                       Make sure that you are logged into the correct Steam account
                       for your FACEIT account.
                     </li>
                   </ol>{" "}
                   <div data-v-ae34ca0e="" className="rules-item-sub-header mb-2">
                     <a
                       data-v-ae34ca0e=""
                       id="15ii"
                       href="#15ii"
                       target="_self"
                       className=""
                     >
                       II.
                     </a>
                     MUST CONNECT FROM A LOBBY
                   </div>{" "}
                   <ol data-v-ae34ca0e="">
                     <li data-v-ae34ca0e="">
                       Exit CS:GO and relaunch the game before joining with the
                       correct IP address through the game console.
                     </li>
                   </ol>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>{" "}
       <div
         data-v-ae34ca0e=""
         className="vue-back-to-top"
         style={{ bottom: 60, right: 60, display: "none" }}
       >
         <button
           data-v-ae34ca0e=""
           type="button"
           className="btn btn-secondary mythic-button btn-to-top"
         >
           <svg
             data-v-ae34ca0e=""
             viewBox="0 0 16 16"
             width="1em"
             height="1em"
             focusable="false"
             role="img"
             aria-label="arrow up"
             xmlns="http://www.w3.org/2000/svg"
             fill="currentColor"
             className="bi-arrow-up b-icon bi"
           >
             <g data-v-ae34ca0e="">
               <path
                 fillRule="evenodd"
                 d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
               />
             </g>
           </svg>
         </button>
       </div>
     </div>
   </Box>

 )
}