import { Box } from "@mui/material";

export const RulesPage = () => {
  return (
    <Box
      bgcolor="#12161bf7"
      padding={"50px"}
      className="main container-fluid"
      color={"white"}
      textAlign={"left"}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-xl-8 offset-xl-2">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col">
                    <h1 className="text-center title anim-fadein-1 mb-1">
                      {/* eslint-disable-next-line no-undef */}
                      {SITE_CONFIG.siteName}{" "}
                      <span
                        style={{ color: "#0dbcfe" }}
                        className="mythic-header"
                      >
                        RULES
                      </span>
                    </h1>{" "}
                    <p className="tagline anim-fadein-2 text-center mb-4">
                      LAST UPDATED: March 26th, 2020
                    </p>
                  </div>
                </div>{" "}
                <Box margin={"100px"}>
                  <div className="row contents-segment mythic-bg rules-item mb-4">
                    <div className="col-md-12">
                      <h2 className="rules-item-header mb-3">CONTENTS</h2>
                    </div>{" "}
                    <div className="rules-contents-col col-md-12">
                      <ol className="rules-contents-list">
                        <li>
                          <a href="#1">SETUP</a>
                        </li>{" "}
                        <li>
                          <a href="#2">QUEUE AND GAME RULES</a>{" "}
                          <ol>
                            <li>
                              <a href="#2i">TEAMS</a>
                            </li>{" "}
                            <li>
                              <a href="#2ii">MAPS</a>
                            </li>{" "}
                            <li>
                              <a href="#2iii">SERVER CONFIGURATION</a>
                            </li>{" "}
                            <li>
                              <a href="#2iv">SERVER LOCATIONS</a>
                            </li>{" "}
                            <li>
                              <a href="#2v">OVERTIME</a>
                            </li>
                          </ol>
                        </li>{" "}
                        <li>
                          <a href="#steam">STEAM ACCOUNT</a>
                        </li>{" "}
                        <li>
                          <a href="#3">COMMUNICATION</a>
                        </li>{" "}
                        <li>
                          <a href="#4">CANCELLATION OF MATCHES</a>{" "}
                          <ol>
                            <li>
                              <a href="#4i">SERVER INSTABILITY</a>
                            </li>{" "}
                            <li>
                              <a href="#4ii">NEW MEMBERS</a>
                            </li>{" "}
                            <li>
                              <a href="#4iii">LEAVERS</a>
                            </li>{" "}
                            <li>
                              <a href="#4iv">MULTIPLE LEAVERS</a>
                            </li>{" "}
                            <li>
                              <a href="#4v">OVERTIME LEAVERS</a>
                            </li>{" "}
                            <li>
                              <a href="#4vi">PLAYER CONNECTION</a>
                            </li>{" "}
                            <li>
                              <a href="#4vii">ADMIN DISCRETION</a>
                            </li>
                          </ol>
                        </li>{" "}
                        <li>
                          <a href="#5">CHEATING, SCRIPTS &amp; EXPLOITS</a>
                        </li>{" "}
                        <li>
                          <a href="#6">ADMIN ABUSE</a>
                        </li>{" "}
                        <li>
                          <a href="#7">CODE OF CONDUCT</a>{" "}
                          <ol>
                            <li>
                              <a href="#7i">ANTI-COMPETITIVE</a>
                            </li>{" "}
                            <li>
                              <a href="#7ii">COMMUNITY MISCONDUCT</a>
                            </li>{" "}
                            <li>
                              <a href="#7iii">DEATH THREATS</a>
                            </li>{" "}
                            <li>
                              <a href="#7iv">FAILURE TO COMMUNICATE</a>
                            </li>{" "}
                            <li>
                              <a href="#7v">FRIENDLY FIRE</a>
                            </li>{" "}
                            <li>
                              <a href="#7vi">GRIEFING</a>
                            </li>{" "}
                            <li>
                              <a href="#7vii">HARASSMENT</a>
                            </li>{" "}
                            <li>
                              <a href="#7viii">INAPPROPRIATE LANGUAGE</a>
                            </li>{" "}
                            <li>
                              <a href="#7ix">INTOXICATION</a>
                            </li>{" "}
                            <li>
                              <a href="#7x">MATCH ABUSE</a>
                            </li>{" "}
                            <ol>
                              <li>
                                <a href="#7xa">WHAT IS SCALING?</a>
                              </li>{" "}
                              <li>
                                <a href="#7xb">WHY SCALE BANS?</a>
                              </li>{" "}
                              <li>
                                <a href="#7xc">HOW DOES SCALING WORK?</a>
                              </li>{" "}
                              <li>
                                <a href="#7xd">CAN I GET MY BAN REMOVED?</a>
                              </li>
                            </ol>{" "}
                            <li>
                              <a href="#7xi">PAUSE ABUSE</a>
                            </li>{" "}
                            <li>
                              <a href="#7xii">STREAMING</a>
                            </li>{" "}
                            <ol>
                              <li>
                                <a href="#7xiia">STREAM SNIPING</a>
                              </li>
                            </ol>{" "}
                            <li>
                              <a href="#7xiii">SUPPORT ABUSE</a>
                            </li>{" "}
                            <li>
                              <a href="#7xiv">THROWING</a>
                            </li>{" "}
                            <ol>
                              <li>
                                <a href="#7xiva">LEADERBOARD MANIPULATION</a>
                              </li>
                            </ol>{" "}
                            <li>
                              <a href="#7xv">TOXICITY</a>
                            </li>
                          </ol>
                        </li>{" "}
                        <li>
                          <a href="#8">PLAYER LOCATION</a>
                        </li>{" "}
                        <li>
                          <a href="#9">MULTIPLE ACCOUNTS</a>{" "}
                          <ol>
                            <li>
                              <a href="#9i">SUSPICIOUS ACCOUNTS</a>
                            </li>{" "}
                            <li>
                              <a href="#9ii">BAN EVASION</a>
                            </li>{" "}
                            <li>
                              <a href="#9iii">ACCOUNT SECURITY</a>
                            </li>
                          </ol>
                        </li>{" "}
                        <li>
                          <a href="#10">REPORTS AND APPEALS</a>{" "}
                          <ol>
                            <li>
                              <a href="#10i">REPORTING</a>
                            </li>{" "}
                            <li>
                              <a href="#10ii">EVIDENCE</a>
                            </li>{" "}
                            <li>
                              <a href="#10iii">APPEALS</a>
                            </li>
                          </ol>
                        </li>{" "}
                        <li>
                          <a href="#11">SEASONS AND PRIZES</a>
                        </li>{" "}
                        <li>
                          <a href="#12">MYTHIC DIAMOND</a>
                        </li>{" "}
                        <li>
                          <a href="#13">FURTHER SUPPORT</a>
                        </li>
                      </ol>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>{" "}
          <div className="col-md-12 col-xl-8 offset-xl-2">
            <div className="row">
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={1} href="#1" target="_self" className="">
                        1.
                      </a>
                      SETUP
                    </div>{" "}
                    <p>
                      Before playing in Mythic League, all players must have
                      completed the
                      <a
                        href="/faq#1"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        Account Setup / Authorization
                      </a>
                      process. Before completing this players may need to:
                    </p>{" "}
                    <ul>
                      <li>
                        Register and configure a FACEIT account (if you do not
                        already have a FACEIT account).
                      </li>{" "}
                      <li>
                        Create a Discord Account (if you do not already have a
                        Discord account).
                      </li>
                    </ul>{" "}
                    <div className="rules-item-sub-header mb-2">
                      <a id="1i" href="#1i" target="_self" className="">
                        I.
                      </a>
                      MINIMUM MATCH LIMIT
                    </div>{" "}
                    <p>
                      In order to provide an extra level of protection and
                      deterrence against Smurfs and Ban Evaders Mythic League
                      has a minimum match limit policy. This requires that all
                      accounts must have completed a certain number of ELO
                      enabled matches before they are allowed to play in Mythic
                      League. Below are the current minimum match limits per
                      division:
                    </p>{" "}
                    <p>
                      Bronze - 15 matches
                      <br />
                      Silver - 50 matches
                      <br />
                      Gold - 100 matches
                    </p>{" "}
                    <p>
                      These limits are subject to change at any time, although
                      the community will be notified if a change is made.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={2} href="#2" target="_self" className="">
                        2.
                      </a>
                      QUEUE AND GAME RULES
                    </div>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="2i" href="#2i" target="_self" className="">
                        I.
                      </a>
                      TEAMS
                    </div>{" "}
                    <p>
                      Captains are assigned by Leaderboard Position (Diamond
                      &amp; Gold Divisions) or ELO (Silver &amp; Bronze
                      Divisions). The captain with the highest position picks
                      first. The pick phase is structured ABBAABBA.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="2ii" href="#2ii" target="_self" className="">
                        II.
                      </a>
                      MAPS
                    </div>{" "}
                    <p>
                      Maps are chosen similarly to the location veto. The
                      assigned captains in the match take turns banning one map
                      until a final map remains. The map pool is as follows:
                    </p>{" "}
                    <ul>
                      <li>Ancient</li> <li>Dust 2</li> <li>Inferno</li>{" "}
                      <li>Mirage</li> <li>Nuke</li> <li>Overpass</li>{" "}
                      <li>Vertigo</li>
                    </ul>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="2iii" href="#2iii" target="_self" className="">
                        III.
                      </a>
                      SERVER CONFIGURATION
                    </div>{" "}
                    <p>
                      All servers have the official competitive ruleset that is
                      used for majors.
                    </p>{" "}
                    <p>
                      All team members have the ability to pause the game by
                      typing !pause in the game's all-chat.
                    </p>{" "}
                    <p>
                      Each team has 5 minutes of pause ability at their
                      disposal. We recommend using this sparingly or for when
                      calling an admin.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="2iv" href="#2iv" target="_self" className="">
                        IV.
                      </a>
                      SERVER LOCATIONS
                    </div>{" "}
                    <p>
                      The location is chosen by the assigned captains for the
                      match during the voting phase. The captains take turns
                      dropping a location until a final location is decided. The
                      following server locations are available for usage:
                    </p>{" "}
                    <ul>
                      <li>North America</li>{" "}
                      <ul>
                        <li>Chicago</li> <li>Dallas</li> <li>Denver</li>
                      </ul>
                    </ul>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="2v" href="#2v" target="_self" className="">
                        V.
                      </a>
                      OVERTIME
                    </div>{" "}
                    <p>
                      Overtime is enabled for all matches in the case of a tie
                      after 30 rounds of regulation play.
                    </p>{" "}
                    <p>The format for overtime is $16,000 Max Rounds 3.</p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div id="steam" className="rules-item-header mb-3">
                      <a id={3} href="#steam" target="_self" className="">
                        3.
                      </a>
                      STEAM ACCOUNT
                    </div>{" "}
                    <p>
                      {" "}
                      To play on our site, your account must meet the
                      requirements specified in the following paragraphs: The
                      account should not have restrictions. To remove
                      restrictions, Steam requires to replenish your account for
                      $5 or make a purchase of game content for the specified
                      amount.{" "}
                    </p>
                    <p>
                      Your account should not have any bans, including bans in
                      the Steam community, trade ban, community ban and hidden
                      ban. The exception is VAC BAN, if it is present at a game
                      that is not present in our service or in tournaments of
                      which you are not going to take part.
                    </p>{" "}
                    <p>
                      Your account must be active, have hours for the last two
                      weeks spent in the games that are used on our website, and
                      also have a minimum playing hours. For more information,
                      contact the support service of our service or the captain
                      of your team, as these figures may change.
                    </p>
                    <p>
                      Access to linking Steam account to your profile on the
                      site may be limited due to violation of the rules of our
                      service. If you think that the block was issued
                      incorrectly, contact the support service of our service.
                      Accounts with established Family View are not subject to
                      binding. To link such an account, you need to go into the
                      Steam profile settings and disable the Family View. The
                      service has a system to reduce the load on the servers. In
                      order to correctly link your Steam account to the profile
                      on the site, the CS: GO and DOTA 2 items must not be
                      present in your account inventory.{" "}
                    </p>
                    <p>
                      Since Steam has a system, which blocks the trade for items
                      for a certain period of time, (new users) our service
                      allows you to send an trade (to a friend, to your other
                      account or site) without accepting it from the other side.
                      After a successful binding, it is allowed to cancel the
                      trade, in order to avoid the trade of items and their
                      subsequent trade ban. After participating in 5 online
                      tournaments, you will need to, without fail, empty your
                      inventory to participate in further tournaments or attach
                      a new account.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={3} href="#3" target="_self" className="">
                        4.
                      </a>
                      COMMUNICATION
                    </div>{" "}
                    <p>
                      English must be spoken at all times in all areas of Mythic
                      League.
                    </p>{" "}
                    <p>
                      This includes but is not limited to: Communicating with
                      admins, Discord voice and text, in-game voice and text,
                      hub overview chat and direct messages.
                    </p>{" "}
                    <p>
                      You must own and use a functioning microphone to
                      participate in Mythic League. Players who are found to be
                      without a functioning microphone will be temporarily
                      suspended until they can prove to an admin that they have
                      a functioning microphone.
                    </p>{" "}
                    <p>
                      All players must be present in their team’s Discord or
                      TeamSpeak (Diamond only) channel for the duration of the
                      match and communicate therein (unless specified by an
                      admin to use in-game communication).
                    </p>{" "}
                    <p>
                      Players that have successfully authorized but are not
                      present in their team’s Discord channel or refuse to join
                      will receive a minimum
                      <a
                        href="https://www.mythicleague.com/rules#7iv"
                        target="_self"
                        className=""
                      >
                        Failure to Communicate
                      </a>
                      penalty of 24 hours.
                    </p>{" "}
                    <p>
                      Players that have successfully authorized whom refuse to
                      communicate in their team’s Discord channel will receive a
                      minimum
                      <a
                        href="https://www.mythicleague.com/rules#7iv"
                        target="_self"
                        className=""
                      >
                        Failure to Communicate
                      </a>
                      penalty of 24 hours.
                    </p>{" "}
                    <p>
                      Ongoing games are not eligible for cancellation due to
                      technical issues with Discord. If technical issues arise
                      the following applies:
                    </p>{" "}
                    <ul>
                      <li>
                        In-game communication will be used in the event of a
                        Discord service outage while a game is in-progress.
                      </li>{" "}
                      <li>
                        Manual matchrooms in Discord will be made available if
                        the AUTO-VOIP Mover is offline. Players are expected to
                        organize themselves into these numbered rooms before
                        their game begins.
                      </li>{" "}
                      <li>
                        An admin must be contacted before any rounds have been
                        completed if a player does not have a functioning
                        microphone.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={4} href="#4" target="_self" className="">
                        5.
                      </a>
                      CANCELLATION OF MATCHES
                    </div>{" "}
                    <p>
                      Matches may only be cancelled under the following criteria
                      or circumstances:
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="4i" href="#4i" target="_self" className="">
                        I.
                      </a>
                      SERVER INSTABILITY ISSUES
                    </div>{" "}
                    <p>
                      If a server issue presents itself before ten (10) rounds
                      have been completed an admin will have the ability to
                      cancel the match.
                    </p>{" "}
                    <p>Server instability is defined as:</p>{" "}
                    <blockquote>
                      "an issue that is clear and identifiable by an admin
                      either independently OR through evidence supplied to an
                      admin by the players. The admin will observe or the
                      evidence will clearly show that: the server has poor
                      performance in the form of high latency, significantly
                      high variance or crashing."
                    </blockquote>{" "}
                    <p>
                      Server issues should clearly be seen to affect the
                      majority of players connected.
                    </p>{" "}
                    <p>Acceptable evidence from players should be:</p>{" "}
                    <ul>
                      <li>
                        net_graph screenshots clearly showing an issue (an admin
                        may ask you to type "status" in the console and then
                        provide a screenshot of the result)
                      </li>{" "}
                      <li>
                        In-game clips or Twitch VODs that show beyond doubt that
                        the server is the issue
                      </li>
                    </ul>{" "}
                    <p>
                      This evidence must be available upon demand and provided
                      before the game can be considered for cancellation.
                    </p>{" "}
                    <p>
                      Cancellations for Server Instability are not guaranteed,
                      the investigating admin will make an informed decision on
                      the need for cancellation.
                    </p>{" "}
                    <p>
                      Server Instability issues that occur after 10 rounds are
                      complete will require the majority (3/5 players) of both
                      teams to agree to a cancellation. If no agreement can be
                      reached the match will continue.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="4ii" href="#4ii" target="_self" className="">
                        II.
                      </a>
                      NEW MEMBERS
                    </div>{" "}
                    <p>
                      Occasionally a situation may arise where a new player to
                      the League has either failed to authorize their Discord
                      before queueing or is unable to join the Discord server
                      altogether.
                    </p>{" "}
                    <p>
                      In this case, it is the responsibility of the players to
                      contact an admin as early as possible.
                    </p>{" "}
                    <p>
                      <u>
                        If you discover a player on your team is not in Discord,
                        the following applies:
                      </u>
                    </p>{" "}
                    <ol>
                      <li>You must contact an admin from your match room.</li>{" "}
                      <li>
                        You must toggle your !pause to prevent the game from
                        progressing further.
                      </li>{" "}
                      <li>
                        You must inform the admin of the situation appropriately
                        and specify the player's FACEIT username.
                      </li>
                    </ol>{" "}
                    <p>
                      <u>
                        If no rounds have been completed, the admin will make
                        one of the following decisions:
                      </u>
                    </p>{" "}
                    <ol>
                      <li>
                        <b>
                          Move the players on your team to a private match
                          channel and the match will continue.
                        </b>
                        This is our preferred method to resolve this situation
                        and will always be the first option.
                      </li>{" "}
                      <li>
                        <b>Rehost the game.</b> If the player needs more time to
                        join or complete authorization, the game may be rehosted
                        with the same configuration.
                      </li>{" "}
                      <li>
                        <b>Cancel the match.</b> If there is no indication that
                        the player’s Discord issues will be resolved in
                        reasonable time then game may be cancelled allowing
                        teammates to requeue.
                      </li>
                    </ol>{" "}
                    <p>
                      <u>
                        If one (1) or more rounds have been completed, the admin
                        will make one of the following decisions:
                      </u>
                    </p>{" "}
                    <ol>
                      <li>
                        <b>
                          Move the players on your team to a private match
                          channel and the match will continue.
                        </b>
                        This is our preferred method to resolve this situation
                        and will always be the first option.
                      </li>{" "}
                      <li>
                        <b>Rehost the game.</b> If the player needs more time to
                        join or complete authorization, the game may be rehosted
                        with the same configuration if both teams agree in the
                        majority (3/5 players).
                      </li>{" "}
                      <li>
                        <b>
                          Instruct players to use in-game communication for this
                          game only.
                        </b>
                        If both teams do not agree to a rehost, the game will
                        not be cancelled and players are to use in-game comms.
                      </li>
                    </ol>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="4iii" href="#4iii" target="_self" className="">
                        III.
                      </a>
                      LEAVERS
                    </div>{" "}
                    <p>
                      Counter-Strike is a competitive game and often causes high
                      tempers or frustration. A minority of players sometimes
                      choose to leave a game early creating issues for the other
                      players. If you wish to cancel a game due to a teammate
                      leaving there are two sets of procedure as detailed below.
                    </p>{" "}
                    <p>
                      <u>
                        If a player leaves your match before 6 rounds have been
                        completed, the following applies:
                      </u>
                    </p>{" "}
                    <ol>
                      <li>
                        You must contact an admin before 6 rounds have been
                        completed.
                      </li>{" "}
                      <li>
                        You must toggle your !pause and call an admin
                        immediately.
                      </li>{" "}
                      <li>
                        You must not wait until your pause time is used before
                        contacting an admin.
                      </li>
                    </ol>{" "}
                    <p>
                      The responding admin will confirm that you are paused and
                      will investigate to the best of their ability whether a
                      player intends to rejoin the game or not. If communication
                      with the player is not possible the admin will ask the
                      team that called the admin to wait an appropriate amount
                      of time to give the player a chance to rejoin.
                    </p>{" "}
                    <p>
                      If the player does not intend to rejoin, fails to rejoin
                      within the 5 minute cooldown OR the given time that an
                      admin states, the player will receive a 24 hour queue
                      cooldown and the game will be cancelled.
                    </p>{" "}
                    <p>
                      <u>
                        If a player leaves your match after 6 rounds have been
                        completed, the following applies:
                      </u>
                    </p>{" "}
                    <ol>
                      <li>You must toggle your !pause and call an admin.</li>{" "}
                      <li>
                        You must have already established with the other team
                        that their majority (3/5 players) will agree to a
                        cancel.
                      </li>
                    </ol>{" "}
                    <p>
                      The responding admin will confirm that you are paused and
                      will contact the other team to confirm that they agree in
                      the majority to cancel the game.
                    </p>{" "}
                    <p>
                      If the other team indicates their agreement to cancel, the
                      leaving player will receive a 24 hour queue cooldown and
                      the game will be cancelled.
                    </p>{" "}
                    <p>
                      If the other team indicates that they do not agree to
                      cancel then the game will be played out. The leaving
                      player will still receive a 24 hour queue cooldown.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="4iv" href="#4iv" target="_self" className="">
                        IV.
                      </a>
                      MULTIPLE LEAVERS
                    </div>{" "}
                    <p>
                      If multiple players leave from <b>one</b> team after 6
                      rounds have been completed, the following applies:
                    </p>{" "}
                    <ol>
                      <li>You must contact an admin from the match page.</li>{" "}
                      <li>You must toggle your !pause.</li>
                    </ol>{" "}
                    <p>
                      The responding admin will confirm that you are paused and
                      identify which users have left the game. They will be
                      given the standard window of five (5) minutes OR a given
                      time that the admin states to return to the game.
                    </p>{" "}
                    <p>
                      If there is no indication that players will return and
                      continue the game, the admin responding will manually set
                      the score of the game to a win (1-0) in the enemy team's
                      favor (the team without leavers). This will end the game,
                      causing ELO and leaderboard points to be added or
                      subtracted in the same manner as a standard win or loss
                      for each team. All players who did not reconnect (or
                      refused to reconnect) will be issued a 48 hour leaver ban.
                    </p>{" "}
                    <p>
                      If multiple players leave from <b>both</b> teams after 6
                      rounds have been completed, the following applies:
                    </p>{" "}
                    <ol>
                      <li>You must contact an admin from the match page.</li>{" "}
                      <li>You must toggle your !pause.</li>
                    </ol>{" "}
                    <p>
                      The responding admin will confirm that you are paused and
                      identify which users have left the game. They will be
                      given the standard window of five (5) minutes OR a given
                      time that the admin states to return to the game.
                    </p>{" "}
                    <p>
                      If there is no indication that players will return and
                      continue the game, the admin responding will cancel the
                      match. All players who did not reconnect (or refused to
                      reconnect) will be deducted the full leaderboard point
                      amount for a loss as well as being issued a 48 hour leaver
                      ban.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="4v" href="#4v" target="_self" className="">
                        V.
                      </a>
                      OVERTIME LEAVERS
                    </div>{" "}
                    <p>
                      Counter-Strike is a competitive game and often causes high
                      tempers or frustration. A minority of players sometimes
                      choose to leave a game early creating issues for the other
                      players. If you wish to cancel a game due to a teammate
                      leaving there are two sets of procedure as detailed below.
                    </p>{" "}
                    <p>
                      <u>
                        If a player leaves a game at the beginning of overtime,
                        the following applies:
                      </u>
                    </p>{" "}
                    <ol>
                      <li>
                        You must contact an admin before overtime progresses
                        beyond the 15-15 scoreline.
                      </li>{" "}
                      <li>
                        You must toggle your !pause to prevent the game from
                        progressing.
                      </li>
                    </ol>{" "}
                    <p>
                      If the responding admin verifies that the player does not
                      intend to rejoin, the game will be cancelled and both
                      teams will receive six (6) leaderboard points as
                      equivalent to a draw result. The player who abandoned the
                      game will have their full leaderboard points removed and
                      be issued a 48 hour ban.
                    </p>{" "}
                    <p>
                      Repeated leaving of games will result in progressively
                      increasing queue cooldowns (see 6.XI.).
                    </p>{" "}
                    <p>
                      Note: Admins responding to your ticket will base their
                      decisions ONLY on the score printed to them at the moment
                      an admin is contacted.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="4vi" href="#4vi" target="_self" className="">
                        VI.
                      </a>
                      PLAYER CONNECTION
                    </div>{" "}
                    <p>Individual connection issues are defined as:</p>{" "}
                    <blockquote>
                      "high ping, variance, choke, loss or skipping affecting a
                      player that is not a confirmable server issue."
                    </blockquote>{" "}
                    <p>
                      An admin may ask you to type "status" in the console and
                      then provide a screenshot of the result.
                    </p>{" "}
                    <p>
                      <u>
                        For players with individual connection issues the
                        following applies:
                      </u>
                    </p>{" "}
                    <ol>
                      <li>
                        You must contact an admin before any rounds have been
                        completed.
                      </li>{" "}
                      <li>
                        You must toggle your !pause to prevent the game from
                        beginning.
                      </li>{" "}
                      <li>
                        You must inform the responding admin whether this issue
                        is location related and can be resolved with a different
                        location, or if the issue is unknown and may persist
                        regardless of location.
                      </li>
                    </ol>{" "}
                    <p>
                      <u>If no rounds have been completed:</u>
                    </p>{" "}
                    <ul>
                      <li>
                        A rehost may be granted allowing for a new server
                        location to be selected. Team and map choices must
                        remain the same.
                      </li>{" "}
                      <li>
                        In the case of the issue being unknown and persistent
                        the admin may choose to cancel the game.
                      </li>
                    </ul>{" "}
                    <p>
                      <u>If one (1) or more rounds have been completed:</u>
                    </p>{" "}
                    <ul>
                      <li>
                        A rehost may only be granted if both teams agree in the
                        majority (3/5 players).
                      </li>
                    </ul>{" "}
                    <p>
                      Cancellations for individual player connection issues are
                      not guaranteed and are at the discretion of the responding
                      admin.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="4vii" href="#4vii" target="_self" className="">
                        VII.
                      </a>
                      ADMIN DISCRETION
                    </div>{" "}
                    <p>
                      In certain situations where a game is suffering a Major
                      Disruption a senior admin may allow a game to be cancelled
                      at any stage.
                    </p>{" "}
                    <p>
                      Major Disruption is defined as an event that is causing
                      the game to be unplayable for one or both teams. The
                      determination of whether there is Major Disruption in a
                      game is at the discretion of senior admins on a case by
                      case basis.
                    </p>{" "}
                    <p>
                      <u>
                        If the following are occurring and you believe they are
                        severe in nature, you may request an admin to observe
                        the game:
                      </u>
                    </p>{" "}
                    <ul>
                      <li>Repeated Friendly Fire by one or more individuals</li>{" "}
                      <li>
                        Repeated and extreme Hate Speech by one or more
                        individuals
                      </li>{" "}
                      <li>Repeated Griefing by one or more individuals</li>{" "}
                      <li>Repeated Throwing by one or more individuals</li>
                    </ul>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={5} href="#5" target="_self" className="">
                        6.
                      </a>
                      CHEATING, SCRIPTS &amp; EXPLOITS
                    </div>{" "}
                    <p>
                      All forms of cheating are strictly forbidden, including
                      but not limited to ESP (wallhacks, radar hacks), aim
                      hacking (silent aim, aimbotting, triggerbotting) and
                      mobility hacks.
                    </p>{" "}
                    <p>
                      Players caught cheating will be permanently banned from
                      Mythic League.
                    </p>{" "}
                    <p>
                      All forms of scripting and macros are forbidden, including
                      but not limited to:
                    </p>{" "}
                    <ul>
                      <li>Bunnyhop scripts/macros</li>{" "}
                      <li>Accuracy scripts or single-bullet fire scripts</li>{" "}
                      <li>Anti-recoil scripts/macros</li>{" "}
                      <li>Name change scripting</li>{" "}
                      <li>Offensive text binds</li>
                    </ul>{" "}
                    <p>Exceptions to this rule are:</p>{" "}
                    <ul>
                      <li>Buy binds</li> <li>Jump throw scripts</li>{" "}
                      <li>Non-offensive text binds</li>
                    </ul>{" "}
                    <p>
                      Exploits are forbidden, this includes but is not limited
                      to:
                    </p>{" "}
                    <ul>
                      <li>Pixel walking</li> <li>Transparent textures</li>
                    </ul>{" "}
                    <div
                      role="alert"
                      aria-live="polite"
                      aria-atomic="true"
                      className="alert rules-alert mb-4 pb-1 alert-info"
                    >
                      {/**/}
                      <p className="font-weight-bold">
                        FACEIT Enhancer / Auto-accept scripts
                      </p>{" "}
                      <p>
                        We allow the use of these plugins on our platform,
                        however any and all issues resulting from their use,
                        including queue bans are your own responsibility.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={6} href="#6" target="_self" className="">
                        7.
                      </a>
                      ADMIN ABUSE
                    </div>{" "}
                    <p>
                      Mythic League offers a team of admins that are: motivated,
                      well trained, respectful, helpful and fair.
                    </p>{" "}
                    <p>
                      If you feel that a decision made by an admin has violated
                      any of our rules as written here or has abused their
                      position please create a report on our
                      <a
                        href="https://ml-face.it/createreport"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        Support Site{" "}
                      </a>
                      . Select the infraction "Admin Abuse" and explain to the
                      best of your ability what happened and why you believe it
                      was admin abuse.
                    </p>{" "}
                    <p>
                      Please do not argue with the admin involved or publicly
                      comment about your suspected admin abuse as this is not
                      productive behavior. You may be muted to prevent community
                      disruption.
                    </p>{" "}
                    <p>
                      This type of report will be exclusively handled by Mythic
                      League management.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={7} href="#7" target="_self" className="">
                        8.
                      </a>
                      CODE OF CONDUCT
                    </div>{" "}
                    <p>
                      Mythic League was created to offer a competitive
                      environment for Counter-Strike that welcomes new and
                      experienced players alike, while encouraging sportsmanship
                      and gameplay development. Mythic League players are
                      expected to demonstrate respect for their peers and the
                      Support Staff. Players berating their team or teammates
                      due to a questionable or poor play will not be tolerated;
                      this behavior will be swiftly and appropriately handled by
                      our Support Staff.
                    </p>{" "}
                    <p>
                      The following headings describe behaviors or actions that
                      will not be tolerated in Mythic League. Players behaving
                      contrary to the Code of Conduct will receive appropriately
                      measured punishments, including: Short-Term Suspension,
                      Long-Term Suspension, or Permanent Removal from the hub.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7i" href="#7i" target="_self" className="">
                        I.
                      </a>
                      ANTI-COMPETITIVE GAMEPLAY
                    </div>{" "}
                    <p>
                      Mythic League players are expected to treat their matches
                      seriously and therefore behave appropriately. Gameplay in
                      which a player is not playing in a serious manner will not
                      be tolerated.
                    </p>{" "}
                    <p>This behavior may include:</p>{" "}
                    <ul>
                      <li>
                        Continuous Force Buying / Not buying with your team
                      </li>{" "}
                      <li>Repeatedly purchasing or using “troll” weapons</li>{" "}
                      <li>Ignoring team communication</li>{" "}
                      <li>Not playing with your team / playing solo</li>{" "}
                      <li>Going AFK at the beginning of rounds</li>{" "}
                      <li>Being AFK for significant periods of time</li>
                    </ul>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7ii" href="#7ii" target="_self" className="">
                        II.
                      </a>
                      COMMUNITY MISCONDUCT
                    </div>{" "}
                    <p>
                      Behavior that is deemed detrimental or disruptive to
                      Mythic League and/or the integrity of the game may result
                      in removal from the League.
                    </p>{" "}
                    <p>This can include but is not limited to:</p>{" "}
                    <ul>
                      <li>
                        Continuous disruptive behavior anywhere in the League
                      </li>{" "}
                      <li>
                        Repeated poor behavior following multiple warnings or
                        punishments
                      </li>{" "}
                      <li>Arguing with or harassing any member of staff</li>{" "}
                      <li>Repeated Support Abuse</li>
                    </ul>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7iii" href="#7iii" target="_self" className="">
                        III.
                      </a>
                      DEATH THREATS
                    </div>{" "}
                    <p>
                      We do not tolerate threats towards other users in Mythic
                      League.
                    </p>{" "}
                    <p>This includes, but is not limited to:</p>{" "}
                    <ul>
                      <li>
                        Messages indicating a threat of bodily harm or violence
                        towards another user.
                      </li>{" "}
                      <li>
                        Instructing a fellow player to harm or kill themselves.
                      </li>
                    </ul>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7iv" href="#7iv" target="_self" className="">
                        IV.
                      </a>
                      FAILURE TO COMMUNICATE
                    </div>{" "}
                    <p>
                      Voice communication is mandatory in Mythic League. You
                      must be an
                      <a
                        href="https://ml-face.it/auth"
                        target="_self"
                        className=""
                      >
                        authorized
                      </a>
                      member of our Discord server and remain connected to your
                      team’s Discord channel for the duration your match.
                    </p>{" "}
                    <p>The following behavior is strictly prohibited:</p>{" "}
                    <ul>
                      <li>Muting and deafening yourself</li>{" "}
                      <li>Muting your teammates in Discord or in-game</li>{" "}
                      <li>
                        Failing to make necessary callouts in Discord (or
                        in-game if an admin has instructed you to. Refer to
                        <a href="#3" target="_self" className="">
                          3. Communication
                        </a>
                        and/or
                        <a href="#4ii" target="_self" className="">
                          4.II New Members
                        </a>
                        where appropriate)
                      </li>
                    </ul>{" "}
                    <p>The exceptions for muting teammates are as follows:</p>{" "}
                    <ul>
                      <li>Muting an extremely toxic teammate</li>{" "}
                      <li>
                        Muting a teammate that is harassing yourself or another
                      </li>{" "}
                      <li>
                        Muting a teammate that is continually spamming the
                        microphone either in Discord or in-game
                      </li>
                    </ul>{" "}
                    <p>
                      If you have muted a teammate you must file a
                      <a
                        href="https://ml-face.it/createreport"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        report
                      </a>
                      on our Support Site with clear evidence as to why it was
                      necessary to mute this teammate.
                    </p>{" "}
                    <p>
                      Failure to provide sufficient evidence for muting a
                      teammate will result in a Failure to Communicate penalty.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7v" href="#7v" target="_self" className="">
                        V.
                      </a>
                      FRIENDLY FIRE
                    </div>{" "}
                    <p>
                      Incidents of intentional team killing and/or team damage.
                    </p>{" "}
                    <p>
                      This includes retaliatory team killing or team damage
                      regardless of whether another user attacks you first as
                      well as in response to another user’s behavior.
                    </p>{" "}
                    <p>
                      Instances of friendly fire will result in a minimum
                      penalty of a 24 hour queue cooldown. Excessive friendly
                      fire will result in extended queue cooldowns and includes
                      the potential for immediate removal from Mythic League.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7vi" href="#7vi" target="_self" className="">
                        VI.
                      </a>
                      GRIEFING
                    </div>{" "}
                    <p>
                      Any behavior which inhibits a teammate's ability to play
                      the game will be considered griefing.
                    </p>{" "}
                    <p>This includes, but is not limited to:</p>{" "}
                    <ul>
                      <li>Intentionally blocking teammates</li>{" "}
                      <li>Intentionally team flashing</li>{" "}
                      <li>Stealing dropped weapons</li>{" "}
                      <li>Microphone spamming</li>{" "}
                      <li>
                        Text spamming of any kind including: binds, foreign
                        languages and symbols
                      </li>
                    </ul>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7vii" href="#7vii" target="_self" className="">
                        VII.
                      </a>
                      HARASSMENT
                    </div>{" "}
                    <p>
                      Targeted toxicity from specific user(s) towards other
                      user(s) over multiple instances.
                    </p>{" "}
                    <p>
                      Releasing any personal information of any other player
                      will lead to a suspension from the league.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7viii" href="#7viii" target="_self" className="">
                        VIII.
                      </a>
                      INAPPROPRIATE LANGUAGE/ICONOGRAPHY
                    </div>{" "}
                    <p>
                      Messages referencing a person's race, ethnicity,
                      nationality, religion, sex, sexual orientation, gender
                      identity or disabilities will lead to an extended ban in
                      Mythic League.
                    </p>{" "}
                    <p>
                      Images and symbols that are offensive in nature are
                      forbidden. This can include but is not limited to:
                    </p>{" "}
                    <ul>
                      <li>Discord profile pictures</li>{" "}
                      <li>FACEIT banner and or profile pictures</li>{" "}
                      <li>Steam profile pictures or content</li>
                    </ul>{" "}
                    <p>
                      If you are displaying an offensive image or symbol you
                      will be asked to remove or change it at the minimum; you
                      may also receive an extended punishment.
                    </p>{" "}
                    <p>
                      We do not tolerate casual use of offensive terminology and
                      you will be warned or punished appropriately.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7ix" href="#7ix" target="_self" className="">
                        IX.
                      </a>
                      INTOXICATION
                    </div>{" "}
                    <p>
                      If a player is intoxicated and there is a clear effect on
                      their performance or the experience of other players in
                      the match, the user will be given a minimum of a 24-hour
                      queue ban.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7x" href="#7x" target="_self" className="">
                        X.
                      </a>
                      MATCH ABUSE
                    </div>{" "}
                    <p>Below are the following cooldowns for match abuse:</p>{" "}
                    <ul>
                      <li>Didn't accept match: 3 minutes.</li>{" "}
                      <ul>
                        <li>
                          User failed to Accept the queue, refer to
                          <a href="#5" target="_self" className="">
                            Section 5
                          </a>
                          if you use FACEIT Enhancer.
                        </li>{" "}
                        <li>Scaling: None</li>
                      </ul>{" "}
                      <li>Captain no-pick: 5 minutes.</li>{" "}
                      <ul>
                        <li>
                          User selected as Captain failed to make a selection
                          for their team.
                        </li>{" "}
                        <li>
                          Scaling: 1<sup>st</sup> - 24 Hours | 2<sup>nd</sup> -
                          24 Hours | 3<sup>rd</sup> - 3 Days | 4<sup>th</sup> -
                          7 Days
                        </li>
                      </ul>{" "}
                      <li>Didn't Connect (AFK): 20 minutes.</li>{" "}
                      <ul>
                        <li>
                          User failed to connect to a game that was properly
                          configured within the 5 minute join timer.
                        </li>{" "}
                        <li>
                          Scaling: 1<sup>st</sup> - 24 Hours | 2<sup>nd</sup> -
                          24 Hours | 3<sup>rd</sup> - 3 Days | 4<sup>th</sup> -
                          7 Days
                        </li>
                      </ul>{" "}
                      <li>Leaver: 24 hours (Gold, Silver, Bronze)</li>{" "}
                      <ul>
                        <li>
                          User disconnected from a game and failed to rejoin
                          within 5 minutes or not at all.
                        </li>{" "}
                        <li>
                          Scaling: 1<sup>st</sup> - 3 Days | 2<sup>nd</sup> - 7
                          Days | 3<sup>rd</sup> - 7 Days
                        </li>
                      </ul>
                    </ul>{" "}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a id="7xa" href="#7xa" target="_self" className="">
                            A.
                          </a>
                          WHAT IS SCALING?
                        </div>{" "}
                        <p>
                          Scaling is the steady increase of ban lengths for the
                          above described Match Abuse cooldowns following
                          repeated offences.
                        </p>
                      </div>{" "}
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a id="7xb" href="#7xb" target="_self" className="">
                            B.
                          </a>
                          WHY SCALE BANS?
                        </div>{" "}
                        <p>
                          Scaling of bans is designed to discourage repeat
                          offenders from "dodging" and leaving matches through
                          extended punishments whilst allowing players with
                          genuine technical issues to not be knocked out of the
                          game for long periods of time.
                        </p>
                      </div>{" "}
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a id="7xc" href="#7xc" target="_self" className="">
                            C.
                          </a>
                          HOW DOES SCALING WORK?
                        </div>{" "}
                        <p>
                          The Mythic League Bot tracks the amount and type of
                          queue bans that each user in the League receives and
                          follows our guidelines when a user exceeds the maximum
                          amount of each type of ban allowed within a 30 day
                          rolling period.
                        </p>{" "}
                        <p>
                          For AFK (fail to connect) and Leaver bans each scaling
                          event takes place on the third (3rd) cooldown that is
                          logged. For Captain no-pick each scaling event takes
                          place on the fifth (5th) cooldown that is logged.
                          After receiving a scaled ban the user will revert to
                          receiving the default cooldowns listed above until
                          they trigger the next scaling event. This cycle will
                          continue and the length of the upgraded bans will
                          steadily increase up until a 7 day cooldown. Beyond
                          this point the Mythic League management will discuss
                          whether the player needs to be removed from the League
                          as continued Match Abuse degrades the experience for
                          all other users.
                        </p>
                      </div>{" "}
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a id="7xd" href="#7xd" target="_self" className="">
                            D.
                          </a>
                          CAN I GET MY BAN REMOVED?
                        </div>{" "}
                        <p>
                          We will not remove Captain No-Pick or AFK (Didn’t
                          Connect) bans under any circumstances other than the
                          following exceptions:
                        </p>{" "}
                        <ul>
                          <li>
                            A known FACEIT issue such as: platform down or a bug
                            communicated to us or confirmable directly by
                            FACEIT.
                          </li>{" "}
                          <li>
                            A known Steam issue such as: CS:GO logins down,
                            Steam down or another widespread verifiable Steam
                            issue.
                          </li>{" "}
                          <li>A CS:GO update.</li>
                        </ul>{" "}
                        <p>
                          <b>NOTE</b>: During Steam or FACEIT outages we will do
                          our best to remove AFK (Didn’t Connect) bans. However,
                          ultimately the responsibility rests with the player to
                          inform us as soon as they receive the ban, as it will
                          count towards your scaled bans threshold (see above)
                          if not removed. There is no guarantee that these bans
                          will be removed at a later time or date, even if they
                          were the result of Steam or FACEIT outages.
                        </p>{" "}
                        <p>
                          Leaver bans may only be removed if one of the
                          following applies:
                        </p>{" "}
                        <ul>
                          <li>
                            Player reconnected and played the remainder of the
                            game.
                          </li>{" "}
                          <li>
                            Player can produce an
                            <a
                              href="/faq#"
                              rel="noopener"
                              target="_blank"
                              className=""
                            >
                              outage map
                            </a>
                            from their service provider (either internet or
                            power).
                          </li>
                        </ul>{" "}
                        <p>
                          If you were able to reconnect to a game that you
                          became disconnected from and play the remainder,
                          please contact an admin in the
                          <a
                            href="https://discordapp.com/channels/411948073271427072/411954282015883275"
                            rel="noopener"
                            target="_blank"
                            className="supportChannel"
                          >
                            #support
                          </a>
                          channel on our Discord and have a teammate confirm.
                          Alternatively you may provide the matchroom URL for
                          that game and an admin will verify through the GOTV
                          that you reconnected.
                        </p>{" "}
                        <p>
                          <b>NOTE</b>: Regardless of whether a game is about to
                          be cancelled, please DO NOT leave the server before
                          you are automatically removed. You will receive an AFK
                          (Didn't Connect) cooldown and we will not lift it.
                        </p>{" "}
                        <div
                          role="alert"
                          aria-live="polite"
                          aria-atomic="true"
                          className="alert rules-alert mb-4 pb-1 alert-info"
                        >
                          {/**/}
                          <p className="font-weight-bold">
                            FACEIT Enhancer / Auto-accept scripts
                          </p>{" "}
                          <p>
                            We allow the use of these plugins on our platform,
                            however any and all issues resulting from their use,
                            including queue bans are your own responsibility.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7xi" href="#7xi" target="_self" className="">
                        XI.
                      </a>
                      PAUSE ABUSE
                    </div>{" "}
                    <p>
                      The !pause feature available in Mythic League matches is
                      intended to be used in the event that an admin must be
                      contacted for assistance. In the event of a player having
                      a technical issue you may use the !pause feature, however
                      if before the completion of six (6) rounds, you must also
                      contact an admin.
                    </p>{" "}
                    <p>
                      Admins may pause the match manually if they deem it
                      necessary, however players should not expect this. Use
                      your 5 minute !pause time wisely and do not waste it under
                      the assumption an admin will extend it for you.
                    </p>{" "}
                    <p>
                      The !pause feature can be used at your team's discretion
                      but should not be abused. The following may be considered
                      Pause Abuse by our admin team and includes, but is not
                      limited to:
                    </p>{" "}
                    <ul>
                      <li>Trolling of any kind</li>{" "}
                      <li>Pausing without good reason</li>{" "}
                      <li>Unpausing the enemy team's pause</li>{" "}
                      <li>
                        Unpausing whilst a user is communicating with an admin
                      </li>
                    </ul>{" "}
                    <p>
                      Players found to be abusing the !pause feature may be
                      subject to a minimum of a 24 hour Pause Abuse cooldown.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7xii" href="#7xii" target="_self" className="">
                        XII.
                      </a>
                      STREAMING
                    </div>{" "}
                    <p>
                      Many Mythic League players are also aspiring streamers, or
                      in some cases already established streamers. We want to
                      support this to the best of our ability, therefore we will
                      not prohibit users from streaming their games when playing
                      in Mythic League.
                    </p>{" "}
                    <p>
                      We do however strongly suggest that you use a delay,
                      preferably a minimum of 30 seconds. This will help to
                      protect you from the potential of an enemy team member
                      viewing your stream for information they may abuse against
                      you.
                    </p>{" "}
                    <p>
                      Please consider that if you stream without delay you are
                      not only potentially impacting your own gameplay
                      experience, but also the experience of your teammates who
                      did not have any say in your decision to stream the game.
                    </p>{" "}
                    <p>
                      If you chose to stream - especially without delay - please
                      remember that you do so at your own risk. If you believe
                      someone is stream sniping please
                      <a
                        href="https://ml-face.it/createreport"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        report
                      </a>
                      them and provide comprehensive and specific evidence.
                    </p>{" "}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a
                            id="7xiia"
                            href="#7xiia"
                            target="_self"
                            className=""
                          >
                            A.
                          </a>
                          STREAM SNIPING
                        </div>{" "}
                        <p>
                          Viewing the stream of a player on the enemy team is
                          strictly prohibited. You may not under any
                          circumstances view or use information from an enemy
                          player’s stream on any platform to gain an advantage.
                        </p>{" "}
                        <p>
                          Players found to be engaging in Stream Sniping will
                          receive a minimum of a seven (7) day queue ban.
                        </p>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7xiii" href="#7xiii" target="_self" className="">
                        XIII.
                      </a>
                      SUPPORT ABUSE
                    </div>{" "}
                    <p>
                      Abusing the Support system that Mythic League offers in
                      any way will result in strict penalties.
                    </p>{" "}
                    <p>This includes but is not limited to:</p>{" "}
                    <ul>
                      <li>
                        Behaving in a rude or disrespectful manner towards a
                        member of the admin team
                      </li>{" "}
                      <li>
                        Failing to respect and accept a decision made by a
                        member of the admin team after their decision and
                        position have been clearly stated
                      </li>{" "}
                      <li>
                        Continually arguing with an admin regarding a ban that
                        has been issued may result in further consequences
                      </li>{" "}
                      <li>
                        Harassing any member of the admin team with unsolicited
                        and/or continuous direct messages
                      </li>{" "}
                      <li>
                        Repeatedly calling an admin for an issue that was
                        already resolved
                      </li>{" "}
                      <li>
                        Repeatedly filing erroneous or unnecessary reports
                      </li>{" "}
                      <li>
                        Repeatedly tagging either a specific admin or all
                        support members in Discord or in the hub overview
                      </li>
                    </ul>{" "}
                    <p>
                      Mythic League admins are volunteers who are here to
                      enforce the rules and apply judgements that are fair to
                      all involved parties. Abuse will not be tolerated.
                    </p>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7xiv" href="#7xiv" target="_self" className="">
                        XIV.
                      </a>
                      THROWING
                    </div>{" "}
                    <p>
                      Behaviors that show a clear attempt to lose the game or
                      round are considered throwing.
                    </p>{" "}
                    <p>This includes but is not limited to:</p>{" "}
                    <ul>
                      <li>Making little or no attempt to kill enemies</li>{" "}
                      <li>
                        Giving up your life on purpose without attempting to
                        contribute in any way
                      </li>{" "}
                      <li>
                        Giving up the bomb or the position or equipment of your
                        team in any way
                      </li>
                    </ul>{" "}
                    <p>
                      Mythic League is a competitive community and we offer
                      prizes as reward for your performance. As such we expect
                      you to play to the best of your ability at all times.
                    </p>{" "}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a
                            id="7xiva"
                            href="#7xiva"
                            target="_self"
                            className=""
                          >
                            A.
                          </a>
                          LEADERBOARD MANIPULATION
                        </div>{" "}
                        <p>
                          Any form of leaderboard manipulation will result in
                          immediate removal from Mythic League and the
                          forfeiture of any and all pending prizes.
                        </p>{" "}
                        <p>
                          Intentionally (or otherwise) throwing FACEIT matches
                          anywhere on the platform in order to maintain a lower
                          ELO and/or maintain your leaderboard position, will
                          result in suspension from Mythic League and forfeiture
                          of any prizes.
                        </p>{" "}
                        <p>This includes but is not limited to:</p>{" "}
                        <ul>
                          <li>Free and Premium queues</li>{" "}
                          <li>Mythic League Community games</li>{" "}
                          <li>Other Organizers</li> <li>Competitions</li>
                        </ul>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="7xv" href="#7xv" target="_self" className="">
                        XV.
                      </a>
                      TOXICITY
                    </div>{" "}
                    <p>
                      Any language in either text or voice that is considered
                      abusive or disruptive to other players.
                    </p>{" "}
                    <p>This includes but is not limited to:</p>{" "}
                    <ul>
                      <li>
                        Constantly criticizing another player’s skill or
                        decision making.
                      </li>{" "}
                      <li>
                        Swearing at or shouting at your teammates, creating a
                        hostile environment.
                      </li>
                    </ul>{" "}
                    <p>
                      Giving suggestions or advice to players in game can help
                      you learn and grow as a player. Doing so in a positive way
                      is encouraged in Mythic League. Be mindful of your
                      inflection and tone as to not come across in a hostile
                      manner.
                    </p>{" "}
                    <p>
                      Learning to accept constructive criticism that is
                      presented in a friendly and polite manner is an important
                      part of growing as a player and as a person. Please do not
                      respond to another player’s advice in a toxic way.
                    </p>{" "}
                    <p>
                      Toxicity will be punished regardless of where it occurs,
                      including but not limited to: Discord voice or text, hub
                      chat or in-game voice or text.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={8} href="#8" target="_self" className="">
                        9.
                      </a>
                      PLAYER LOCATION
                    </div>{" "}
                    <p>
                      Mythic League is a North American, Central Hub. In order
                      to provide the best experience for all players our
                      Divisions have geographical restrictions. In order to
                      participate in Mythic League you must reside in one of the
                      following whitelisted locations:
                    </p>{" "}
                    <p>
                      Canada; Mexico; United States of America; Anguilla;
                      Antigua and Barbuda; Aruba; Bahamas; Barbados; Belize;
                      Bermuda; Cayman Islands; Costa Rica; Curacao; Dominica;
                      Dominican Republic; El Salvador; Greenland; Grenada;
                      Guadeloupe; Haiti; Honduas; Jamaica; Martinique;
                      Montserrat; Nicaragua; Puerto Rico; Saint Barthelemy;
                      Saints Kitts and Nevis; Saint Lucia; Saint Pierre and
                      Miquelon; Saint Vincent and the Grenadines; Saint
                      Eustatius and Saba; Trinidad and Tobago; Turks and Caicos
                      Islands; United States MOI.
                    </p>{" "}
                    <p>
                      Players found to be participating from any locations that
                      are not listed above or have circumvented these
                      geographical restrictions will be removed from the League.
                    </p>{" "}
                    <p>
                      Mythic League reserves the right to remove players with
                      unstable connections that are consistently harming the
                      competitive nature of the game. If a player is removed and
                      resides within the approved countries for the league, they
                      will be eligible for reimbursement.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={9} href="#9" target="_self" className="">
                        10.
                      </a>
                      MULTIPLE ACCOUNTS / SMURFING
                    </div>{" "}
                    <p>
                      Registering multiple FACEIT accounts is against the FACEIT
                      Terms of Service and is strictly prohibited.
                    </p>{" "}
                    <p>
                      You may not register an alternate account for any reason,
                      including
                    </p>{" "}
                    <ul>
                      <li>Attempting to evade a FACEIT issued ban</li>{" "}
                      <li>Attempting to evade a Mythic League issued ban</li>{" "}
                      <li>
                        Attempting to evade a Valve issued ban (VAC/Game bans
                        less than 365 days old)
                      </li>{" "}
                      <li>
                        Attempting to play at a lower skill level in order to
                        win prizes
                      </li>
                    </ul>{" "}
                    <p>
                      All alternate accounts will be removed from Mythic League
                      and suspended from the FACEIT platform and are not
                      eligible for refunds of any kind. Players who register an
                      alternate account will receive no less than a three (3)
                      day queue ban on their main account.
                    </p>{" "}
                    <p>
                      Mythic League relinquishes all responsibility in the event
                      of:
                    </p>{" "}
                    <ul>
                      <li>
                        Users wishing to switch to a different FACEIT account.
                      </li>{" "}
                      <li>
                        Users wishing to change the Steam account associated
                        with their FACEIT account.
                      </li>{" "}
                      <li>
                        Users who lose access to either their Steam or FACEIT
                        account.
                      </li>
                    </ul>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="9i" href="#9i" target="_self" className="">
                        I.
                      </a>
                      SUSPICIOUS ACCOUNTS
                    </div>{" "}
                    <p>
                      Mythic League reserves the right to indefinitely suspend
                      user accounts that are considered to be suspicious in any
                      form.
                    </p>{" "}
                    <p>
                      Suspended account holders may be asked to provide further
                      information in order to have their suspension removed. If
                      the information provided by the account holder does not
                      satisfactorily explain the suspicious aspect(s) of the
                      account, it will remain suspended.
                    </p>{" "}
                    <p>
                      All suspension related queries and communications will be
                      handled strictly through the suspension Warning on our
                      support website
                      <a
                        href="https://support.mythicleague.com/warnings"
                        target="_self"
                        className=""
                      >
                        support.mythicleague.com
                      </a>
                      and through no other channels.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="9ii" href="#9ii" target="_self" className="">
                        II.
                      </a>
                      BAN EVASION
                    </div>{" "}
                    <p>
                      Users found to be evading a previously issued Mythic
                      League ban will:
                    </p>{" "}
                    <ul>
                      <li>
                        Have the evading account permanently removed from Mythic
                        League
                      </li>{" "}
                      <li>Have the ban on their original account extended</li>
                    </ul>{" "}
                    <p>
                      Any attempt to evade a permanent ban issued by Mythic
                      League may void the account holder from future appeals to
                      return to the league.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="9iii" href="#9iii" target="_self" className="">
                        III.
                      </a>
                      ACCOUNT SECURITY
                    </div>{" "}
                    <p>
                      The security of each user’s account is the responsibility
                      of the owner and as such we expect that each user will
                      take the appropriate steps towards maintaining their
                      account security.
                    </p>{" "}
                    <p>Ensure your account security by:</p>{" "}
                    <ul>
                      <li>
                        Enabling 2-step or 2-factor authorization on all
                        possible accounts (email, FACEIT, Steam)
                      </li>{" "}
                      <li>
                        Ensuring that no other users are allowed access at any
                        point to any of your accounts
                      </li>{" "}
                      <li>Do not click untrusted links that are sent to you</li>{" "}
                      <li>
                        Do not divulge personal or account information to
                        unknown soliciting party
                      </li>
                    </ul>{" "}
                    <p>
                      Refer to:
                      <a
                        href="https://ml-face.it/faceit2fa"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        https://ml-face.it/faceit2fa
                      </a>
                      and
                      <a
                        href="https://ml-face.it/steamguard"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        https://ml-face.it/steamguard
                      </a>
                      to ensure that your FACEIT and Steam accounts are using
                      the highest level of security
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={10} href="#10" target="_self" className="">
                        11.
                      </a>
                      REPORTS AND APPEALS
                    </div>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="10i" href="#10i" target="_self" className="">
                        I.
                      </a>
                      REPORTING
                    </div>{" "}
                    <p>
                      Please report users who are breaking rules by creating a
                      report through the Mythic League
                      <a
                        href="https://support.mythicleague.com"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        support portal{" "}
                      </a>
                      . Evidence must be provided for each report submitted.
                    </p>{" "}
                    <p>
                      We recommend that you setup NVIDIA Instant Replay
                      (Shadowplay), or any third party software to record your
                      in-game communications, as this is not recorded in the
                      match GOTV. We do not expect you to record your entire
                      game, simply use the instant replay feature to record the
                      incidents when and if they occur.
                    </p>{" "}
                    <p>
                      We have provided some video tutorial to help you get
                      started:
                    </p>{" "}
                    <ul>
                      <li>
                        GeForce Experience - Instant Replay:
                        <a href="https://youtu.be/n34x-fxxKus" target="_blank">
                          https://youtu.be/n34x-fxxKus
                        </a>
                      </li>
                    </ul>
                  </div>{" "}
                  <div className="mb-4 col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="10ii" href="#10ii" target="_self" className="">
                        II.
                      </a>
                      EVIDENCE
                    </div>{" "}
                    <p>
                      Please be as specific as possible when submitting reports,
                      including the approximate rounds in which the incident
                      occured. Reports without user submitted evidence or any
                      indication that evidence can be found within the GOTV
                      itself will be closed immediately without action.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="10iii" href="#10iii" target="_self" className="">
                        III.
                      </a>
                      APPEALS
                    </div>{" "}
                    <p>
                      Depending on the type of infraction you received a ban
                      for, you may be eligible to appeal the decision and have
                      the ban either shortened or removed.
                    </p>{" "}
                    <p>
                      To find out whether your ban is eligible for appeal please
                      refer to the
                      <a
                        href="https://ml-face.it/mywarnings"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        warnings
                      </a>
                      page OR refer to the email you received automatically if
                      you have email notifications enabled.
                    </p>{" "}
                    <p>
                      If you feel you have been unfairly banned and are eligible
                      to appeal, you may click the appeal button and leave a
                      comment explaining why you think the ban was made in error
                      or should be repealed.
                    </p>{" "}
                    <p>
                      Appeals are handled exclusively by Mythic League
                      management.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={11} href="#11" target="_self" className="">
                        12.
                      </a>
                      SEASONS AND PRIZES
                    </div>{" "}
                    <p>
                      Each division operates on a one (1) month season length.
                      You can see the end date and time local to yourself of
                      each season if you navigate to your division’s hub and
                      select Leaderboards.
                    </p>{" "}
                    <p>
                      Bronze, Silver, Gold and Diamond Divisions operate with
                      leaderboards that reset every two (2) weeks with the top
                      ten (10) placing individuals receiving prizes.
                    </p>{" "}
                    <p>
                      Diamond Division players can earn invites to FPL-C
                      Qualifiers by placing in the top 45 of the monthly season.
                    </p>{" "}
                    <p>
                      Prizes for all divisions are confirmed by your position at
                      the end of each leaderboard and are distributed following
                      the conclusion of each season (end of each month) within
                      one (1) to two (2) weeks.
                    </p>{" "}
                    <p>
                      Players in all divisions should remember to set their
                      Steam tradelink on their Mythic League
                      <a
                        href="https://ml-face.it/profile"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        profile
                      </a>
                      page and ensure that their Steam profile privacy allows
                      incoming trade offers. Players in Mythic Diamond should
                      remember to additionally set their PayPal address on their
                      Mythic League
                      <a
                        href="https://ml-face.it/profile"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        profile
                      </a>
                      page if you place for a cash prize.
                    </p>{" "}
                    <p>
                      Players have 90 days after the conclusion of the monthly
                      season to submit their tradelink, after which the prize is
                      forfeit.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={12} href="#12" target="_self" className="">
                        13.
                      </a>
                      MYTHIC DIAMOND
                    </div>{" "}
                    <p>
                      The following rules apply only for participating Mythic
                      League Diamond members:
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="12i" href="#12i" target="_self" className="">
                        I.
                      </a>
                      TEAM COMMUNICATION
                    </div>{" "}
                    <p>
                      Mythic Diamond will utilize a private TeamSpeak server for
                      the match communications. All players must be present and
                      communicate effectively throughout the entire duration of
                      a match.
                    </p>{" "}
                    <p>
                      The server password will change twice per month. Players
                      may find these details under the Invite tab on our support
                      website (support.mythicleague.com).
                    </p>{" "}
                    <p>
                      Any leaking or sharing of the TeamSpeak information will
                      result in severe punishment.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="12ii" href="#12ii" target="_self" className="">
                        II.
                      </a>
                      CANCELLATION OF GAMES
                    </div>{" "}
                    <p>
                      Players may request cancellation of a match within the
                      first 10 rounds of the game. You must use the Contact
                      Admin function in the matchroom lobby before round 11
                      begins. After 10 rounds are completed the game will
                      continue regardless. If after 10 rounds the majority of
                      both teams agree, the match can still be cancelled,
                      however we would expect this to be a rarity.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="12iii" href="#12iii" target="_self" className="">
                        III.
                      </a>
                      MATCH ABUSE
                    </div>{" "}
                    <p>
                      Didn’t Accept: 3 Minutes
                      <br />
                      Captain Failed to Pick: 5 Minutes
                      <br />
                      Didn’t Connect: 20 Minutes
                      <br />
                      Leaver: 1 Day
                      <br />
                    </p>{" "}
                    <p>
                      The above match abuse cooldowns will not stack
                      automatically as with other Mythic divisions, however they
                      will be tracked and displayed to admins.
                    </p>{" "}
                    <p>
                      We will not tolerate match abuse (dodging, leaving)
                      whatsoever in Mythic Diamond. Players who have a pattern
                      of any of these ban types that we suspect is abuse will be
                      removed from the division.
                    </p>{" "}
                    <p>If you are disconnected from a match in progress:</p>{" "}
                    <ul>
                      <li>
                        Contact @support in the #support channel of the Mythic
                        League discord and inform us. Make all attempts to
                        rejoin and complete the match where possible.
                      </li>{" "}
                      <li>
                        Your team will be able to pause for you, if you are
                        certain you will be able to rejoin but need more time
                        than the team has remaining in their pause, contact an
                        admin and request an extension of the pause. Admins will
                        grant pause extensions for you to rejoin but the length
                        of time they will extend the pause for will be based on
                        their own discretion.
                      </li>
                    </ul>{" "}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a
                            id="12iiia"
                            href="#12iiia"
                            target="_self"
                            className=""
                          >
                            A.
                          </a>
                          LEAVER BANS
                        </div>{" "}
                        <p>Rejoined the game:</p>{" "}
                        <p>
                          If you received a Leaver ban but were able to rejoin
                          and play the game to completion, please come to the
                          #support channel after the game concludes and ask an
                          admin to unban you. Admins will review the logs for
                          the game and confirm that you rejoined before removing
                          your Leaver ban.
                        </p>{" "}
                        <p>Internet/Power outages:</p>{" "}
                        <p>
                          If you are unable to rejoin a match regardless of
                          connection issues you will receive a 1 day ban that
                          will not be lifted.
                        </p>{" "}
                        <p>
                          If you receive a Leaver ban due to either your
                          internet or power suffering an outage, you must
                          present the supplier’s official outage report to an
                          admin.
                        </p>{" "}
                        <p>
                          NOTE: Please do not share private personal details
                          directly in the #support channel. Wait to direct
                          message the responding admin.
                        </p>
                      </div>{" "}
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a
                            id="12iiib"
                            href="#12iiib"
                            target="_self"
                            className=""
                          >
                            B.
                          </a>
                          NOSHOW (DIDN'T CONNECT)
                        </div>{" "}
                        <p>
                          The only time we will remove this category of ban is
                          when there is a CS:GO update, FACEIT platform outage
                          or Steam/CSGO outage.
                        </p>{" "}
                        <p>
                          NOTE: Corrupted client sided files are not included
                          here.
                        </p>
                      </div>{" "}
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a
                            id="12iiic"
                            href="#12iiic"
                            target="_self"
                            className=""
                          >
                            C.
                          </a>
                          NOPICK (DIDN'T PICK)
                        </div>{" "}
                        <p>We will never remove this category of ban.</p>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="12iv" href="#12iv" target="_self" className="">
                        IV.
                      </a>
                      CAPTAIN PICK
                    </div>{" "}
                    <p>
                      The current captain selection method enabled in Mythic
                      Diamond is “leaderboard placement”.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="12v" href="#12v" target="_self" className="">
                        V.
                      </a>
                      MATCH MEDIC
                    </div>{" "}
                    <p>
                      Match Medic will only be used by admins in the following
                      circumstances:
                    </p>{" "}
                    <ul>
                      <li>Serious server disruption</li>{" "}
                      <li>Complete server failure</li>{" "}
                      <li>
                        Two or more players with a ping consistently 150 or
                        above
                      </li>
                    </ul>{" "}
                    <p>
                      The responding admin will make an informed decision after
                      assessing the situation before approving or denying a
                      Match Medic. You may be asked to provide screenshots,
                      clips or console output.
                    </p>{" "}
                    <p>
                      If the above circumstances are not present but both teams
                      agree to move server location it will still be possible.
                    </p>{" "}
                    <p>
                      If both teams agree in the majority a server location
                      change may be granted.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="12vi" href="#12vi" target="_self" className="">
                        VI.
                      </a>
                      ETHICS
                    </div>{" "}
                    <p>
                      Unsportsmanlike behavior will not be tolerated in Mythic
                      Diamond.
                    </p>{" "}
                    <p>
                      We expect that all Mythic Diamond players to conduct
                      themselves appropriately at all times and in all aspects
                      of the division. We will not tolerate Toxicity,
                      Anti-Competitive gameplay, Friendly Fire (zero tolerance),
                      disruption or misconduct of any kind.
                    </p>{" "}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="rules-item-sub-sub-header mb-2">
                          <a
                            id="12via"
                            href="#12via"
                            target="_self"
                            className=""
                          >
                            A.
                          </a>
                          PRINCIPLES
                        </div>{" "}
                        <p>
                          We expect that all players in Mythic Diamond will:
                        </p>{" "}
                        <ul>
                          <li>
                            Play to the best of their ability in every game
                          </li>{" "}
                          <li>
                            Maintain the best possible mindset in every game
                          </li>{" "}
                          <li>Treat their peers with respect at all times</li>{" "}
                          <li>
                            Treat the support staff with respect at all times
                          </li>{" "}
                          <li>
                            Refrain from holding grudges against their peers
                            based on previous interactions
                          </li>
                        </ul>{" "}
                        <p>
                          Players who refuse to follow these principles will be
                          removed.
                        </p>{" "}
                        <p>
                          This division was created for players at and just
                          below the FPL - Challenger level of skill, in order
                          for players to improve and play with equally skilled
                          and like minded players. Players who cannot abide by
                          the principles of the division will face penalty -
                          regardless of their skill, status or level of
                          experience.
                        </p>{" "}
                        <p>
                          There is to be no retaliation in game either, you will
                          be treated the same whether or not you instigated any
                          argument or incident.
                        </p>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="12vii" href="#12vii" target="_self" className="">
                        VII.
                      </a>
                      REPORTING AND APPEALS
                    </div>{" "}
                    <p>
                      All reports against players and appeals regarding any
                      punishments must be submitted through the Mythic League
                      support website (support.mythicleague.com). It is
                      unacceptable to directly message any member of the support
                      staff through Discord, FACEIT or Steam in regards to
                      reports, punishments or player performance. All matters
                      are to be handled privately through our support system.
                      Players who are unable to follow these instructions and
                      contact support staff directly will be ignored and may
                      have any appeal closed summarily.
                    </p>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="rules-item-sub-header mb-2">
                      <a id="12viii" href="#12viii" target="_self" className="">
                        VIII.
                      </a>
                      RECOMMENDATIONS/PLAYER PERFORMANCE
                    </div>{" "}
                    <p>
                      In the final week of each monthly season players will be
                      asked to fill out a short form in which they may recommend
                      players for the division as well as leave feedback
                      regarding players who are considered to not be performing
                      or behaving. We expect reasoning to be well structured and
                      proper examples to be provided. Support staff may contact
                      you with additional queries regarding your feedback.
                    </p>{" "}
                    <p>
                      NOTE: While all feedback and recommendations will be
                      reviewed and considered, we make no guarantees that your
                      specific feedback will result in removals or additions to
                      the division.
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-12">
                <div className="row rules-item mythic-bg mb-4">
                  <div className="col-md-12">
                    <div className="rules-item-header mb-3">
                      <a id={13} href="#13" target="_self" className="">
                        14.
                      </a>
                      FURTHER SUPPORT
                    </div>{" "}
                    <p>
                      If you require further support, please
                      <span className="color-role-support">@Support</span> in
                      the
                      <a
                        href="https://discordapp.com/channels/411948073271427072/411954282015883275"
                        rel="noopener"
                        target="_blank"
                        className=""
                      >
                        #support
                      </a>
                      channel of our Discord server.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div
          className="vue-back-to-top"
          style={{ bottom: 60, right: 60, display: "none" }}
        >
          <button
            type="button"
            className="btn btn-secondary mythic-button btn-to-top"
          >
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              focusable="false"
              role="img"
              aria-label="arrow up"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi-arrow-up b-icon bi"
            >
              <g>
                <path
                  fillRule="evenodd"
                  d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </Box>
  );
};
