import { Box, styled } from "@mui/material";
import { GameIcon } from "../components/GameIcon";

import rust from "../pics/rust.jpg";
import dota from "../pics/dota.jpg";
import tf from "../pics/tf.jpg";
import csgo from "../pics/csgo.jpg";

import rustIc from "../pics/rust.svg";
import dotaIc from "../pics/dota.svg";
import tfIc from "../pics/tf.svg";
import csgoIc from "../pics/csgo.svg";

import csmoney from "../pics/csmoney.png"
import aimlab from "../pics/aimlab.png"

import disc from "../pics/assets/disc.png"
import fpl from "../pics/assets/fpl.png"
import prize from "../pics/assets/prize.png"
import support from "../pics/assets/support.png"
import { Link } from "react-router-dom";
import BasicModal from "../components/Modal";
import { useState } from "react";

const SelectButton = styled('a')({
  cursor: 'pointer',
  border: '3px solid #0dbcfe',
  padding: '10px 20px',
  color: '#0dbcfe',
  fontSize: 20,
  textDecoration: 'none',
  "&:hover": {
    color: '#3c95b1',
  }
})

export const MainPage = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleOpen = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setModalOpen(true)
  };
  const handleClose = () => setModalOpen(false);
  return(
    <Box>
      <Box bgcolor="#090a17c9" position="relative" height="auto" overflow="hidden" color="white" padding={10}>
        <Box maxWidth={"600px"} margin="auto">
          {/* eslint-disable-next-line no-undef */}
          <img style={{width: "100%"}} src={SITE_CONFIG.bigLogo} alt=""/>
        </Box>
        {/* eslint-disable-next-line no-undef */}
        <h1>Welcome to <strong style={{color: "#0dbcfe", fontWeight: 900}}>{SITE_CONFIG.siteName}</strong></h1>
        <h3>NORTH AMERICA'S OFFICIAL PATHWAY TO PRO LEAGUE</h3>
        <h5>POWERED BY</h5>
        <Box width="300px" margin="auto" display="flex" justifyContent="center" alignItems="center">
          <a href="https://cs.money/"><img width={250} src={csmoney} alt=""/></a>
          <a href="https://aimlab.gg"><img width={250} src={aimlab} alt=""/></a>
        </Box>
        <Box margin="30px">
          <SelectButton href="#games">
            SELECT GAME
          </SelectButton>
        </Box>
        <video style={{ width: '100%', position: 'absolute', top: 0, left: 0, zIndex: -1}} loop autoPlay muted>
          <source src="https://cs.liiift.io/v1/RBMN/pd/2/AW/2Z/BN/9X/11/12/FO-2AW2ZBN9X1112.mp4" type="video/mp4"/>
        </video>
      </Box>
      <Box color="white">
        {/* eslint-disable-next-line no-undef */}
        <h1 style={{ margin: "50px" }}>What is <strong style={{color: "#0dbcfe", fontWeight: 900}}>{SITE_CONFIG.siteName}</strong>?</h1>
        <Box display="grid" gap="50px" bgcolor="#12161bf7" padding="100px" justifyContent="center" gridTemplateColumns="repeat(auto-fill, 300px)">
          <Box>
            {/* eslint-disable-next-line no-undef */}
            <img src={BASE_URL + disc} alt=""/>
            <h4 style={{color: "#0dbcfe"}}>
              MANDATORY DISCORD
            </h4>
            <p style={{textAlign: "left"}}>
              {/* eslint-disable-next-line no-undef */}
              Communication is key. {SITE_CONFIG.siteName} strives to provide an experience as close as possible to the way the pros play in FPL. All players use Discord for in-game communication and each team has its own private discord channel for every match. We make the process simple with our AUTO VOIP Finder, which automatically places you into your team's discord channel.
            </p>
          </Box>
          <Box>
            {/* eslint-disable-next-line no-undef */}
            <img src={BASE_URL + support} alt=""/>
            <h4 style={{color: "#0dbcfe"}}>
              24/7 ADMIN SUPPORT
            </h4>
            <p style={{textAlign: "left"}}>
              {/* eslint-disable-next-line no-undef */}
              {SITE_CONFIG.siteName} has a well-trained support staff available 24/7 to provide assistance with any issues that may arise. Our comprehensive ruleset is tough on toxicity and anti-competitive gameplay and we take pride in keeping the community as clean and fair as possible by removing smurfs and ban evaders swiftly.
            </p>
          </Box>
          <Box>
            {/* eslint-disable-next-line no-undef */}
            <img src={BASE_URL + fpl} alt=""/>
            <h4 style={{color: "#0dbcfe"}}>
              PATHWAY TO FPL
            </h4>
            <p style={{textAlign: "left"}}>
              {/* eslint-disable-next-line no-undef */}
              {SITE_CONFIG.siteName} consists of three open solo queue divisions: Bronze, Silver and Gold as well as one invitation only division, Mythic Diamond. Each division follows the same format as the FACEIT Pro League. These divisions are divided by FACEIT ranks, allowing you to queue only with players from the same skill bracket. Each month the top 45 players in Mythic Diamond and top 5 in Mythic Gold will face off in FACEIT's FPL-C qualifier with the top 5 securing an FPL Challenger position.
            </p>
          </Box>
          <Box>
            {/* eslint-disable-next-line no-undef */}
            <img src={BASE_URL + prize} alt=""/>
            <h4 style={{color: "#0dbcfe"}}>
              $5,000+ MONTHLY PRIZES
            </h4>
            <p style={{textAlign: "left"}}>
              {/* eslint-disable-next-line no-undef */}
              {SITE_CONFIG.siteName} seasons are monthly and are divided into two prize leaderboards that run consecutively, allowing everyone a better chance to claim a prize. Players placing in the top 10 of each two week leaderboard can earn CS:GO skins in Bronze, Silver and Gold or cash prizes in Mythic Diamond. {SITE_CONFIG.siteName} provides over $5,000 between these leaderboards and other events!
            </p>
          </Box>
        </Box>
      </Box>
      <Box color="white" margin="50px">
        <h1>Select <strong style={{color:"#0dbcfe"}}>GAME</strong></h1>
        <Box id="games" sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          gridTemplateAreas: `
          "game game"
          "game game"
          `,
          gap:'50px',
          margin: '50px'
        }}>
          {/* eslint-disable-next-line no-undef */}
          <GameIcon onClick={handleOpen} icon={`${BASE_URL}${csgoIc}`} background={BASE_URL + csgo} players={1143} teams={243} lobbies={249}/>
          {/* eslint-disable-next-line no-undef */}
          <GameIcon onClick={handleOpen} icon={BASE_URL + rustIc} background={BASE_URL + rust} players={345} teams={83} lobbies={45}/>
          {/* eslint-disable-next-line no-undef */}
          <GameIcon onClick={handleOpen} icon={BASE_URL + dotaIc} background={BASE_URL + dota} players={1560} teams={340} lobbies={294}/>
          {/* eslint-disable-next-line no-undef */}
          <GameIcon onClick={handleOpen} icon={BASE_URL + tfIc} background={BASE_URL + tf} players={154} teams={43} lobbies={30}/>
        </Box>
      </Box>
      <Box bgcolor="#12161bf7" color="white" padding="30px">
        {/* eslint-disable-next-line no-undef */}
        <h1>Community <strong style={{color: "#0dbcfe", fontWeight: 900}}>{SITE_CONFIG.siteName}</strong></h1>
        <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" margin="50px">
          <p style={{textAlign: 'left', width: '600px', display:'block'}}>
            {/* eslint-disable-next-line no-undef */}
            Don't feel like queueing alone? Join our {SITE_CONFIG.siteName} Community
            hub and find like minded individuals to queue with while
            {/* eslint-disable-next-line no-undef */}
            supporting and representing {SITE_CONFIG.siteName} on FACEIT!<br/><br/>
            The experience is free for all users but you can subscribe to
            {/* eslint-disable-next-line no-undef */}
            support {SITE_CONFIG.siteName} and gain access to subscriber benefits.<br/><br/>
            {/* eslint-disable-next-line no-undef */}
            Subscribers will receive a {SITE_CONFIG.siteName} badge, access to
            {/* eslint-disable-next-line no-undef */}
            special subscriber only leaderboards, access to our {SITE_CONFIG.siteName}
            custom emotes to use anywhere on FACEIT and more.<br/><br/>

            Organize your squad of 2-5 players or create an open lobby with
            your desired skill bracket in order to queue. Feel free to use
            our Community voice channels on Discord with your team for
            clearer communication.<br/><br/>

            All matches will take place in regular FACEIT however you can
            report any players who aren’t taking it seriously on our
            website.
          </p>
          <Box margin={20} maxWidth={"400px"} minWidth={"200px"}>
            {/* eslint-disable-next-line no-undef */}
            <img src={SITE_CONFIG.bigLogo} style={{width: '100%'}} alt=""/>
          </Box>
        </Box>
      </Box>
      <BasicModal handleClose={handleClose} isOpen={modalOpen} />
    </Box>
  )
}