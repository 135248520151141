import { Box } from "@mui/material";
import rustIc from "../../pics/rust.svg";
import dotaIc from "../../pics/dota.svg";
import tfIc from "../../pics/tf.svg";
import csgoIc from "../../pics/csgo.svg";
import Button from "@mui/material/Button";

export const MatchItem = ({team, handleModal}) => {
  const game = {
    csgo: csgoIc,
    dota: dotaIc,
    rust: rustIc,
    tf: tfIc
  }
  return(
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} flex={"0 30%"} width={"200px"} bgcolor={'#0dbcfe0f'} borderRadius={'5px'} margin={'5px'} padding={'5px'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>

        <Box width={'80px'}><img width={'100%'} src={team?.teamOne?.logo} alt=""/></Box>
        <strong>{team?.teamOne?.name}</strong>
      </Box>
      <Box width={'50px'} textAlign={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Box>
          {/* eslint-disable-next-line no-undef */}
          <img width={'30px'} src={BASE_URL +game[team?.game]} alt=""/></Box>
        <small style={{color: "#0dbcfe"}}><strong>VS</strong></small>
        <strong style={{fontSize: '10px'}}>{team?.map}</strong>
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box width={'80px'}><img width={'100%'} src={team?.teamTwo?.logo} alt=""/></Box>
        <strong>{team?.teamTwo?.name}</strong>
      </Box>
    </Box>
  )
}