import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { MainPage } from "./pages/MainPage";
import Navbar from "./components/Navbar";
import { Background } from "./components/Background";
import { Box } from "@mui/material";
import { FaqPage } from "./pages/FaqPage";
import { RulesPage } from "./pages/RulesPage";
import { TeamsPage } from "./pages/TeamsPage";
import { MatchPage } from "./pages/MatchPage";

export const Router = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<MainPage />}/>
        <Route path="/faq" element={<FaqPage/>}/>
        <Route path="/rules" element={<RulesPage />} />
        <Route path="/teams" element={<TeamsPage />} />
        <Route path="/matches" element={<MatchPage />} />
        <Route path="/matches/:game" element={<MatchPage />} />
      </Routes>
      <Background />
      <Box color="white" margin={3}>
        {/* eslint-disable-next-line no-undef */}
        {SITE_CONFIG.siteName} © 2018 - 2022. All rights reserved.
      </Box>
    </BrowserRouter>
  )
}
