import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { TeamItem } from "../components/TeamItem";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import BasicModal from "../components/Modal";
import { MatchItem } from "../components/MatchItem";

export const MatchPage = () => {
  const { game } = useParams();
  const [modalOpen, setModalOpen] = useState(false)
  const handleOpen = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setModalOpen(true)
  };
  const handleClose = () => setModalOpen(false);

  const [match, setMatch] = useState([
    {
      teamOne: {
        logo: 'https://gamepedia.cursecdn.com/cod_esports_gamepedia_en/thumb/1/1b/FURY_Gaminglogo_square.png/630px-FURY_Gaminglogo_square.png',
        name: 'Sonara',
      },
      teamTwo: {
        logo: 'https://img.progamestars.com/media/logo/t49982.png?18',
        name: 'Vertis',
      },
      game: 'csgo',
      map: 'DE_DUST 2'
    },
    {
      teamOne: {
        logo: 'https://static.hltv.org/images/team/logo/6790',
        name: 'Chu Clan',
      },
      teamTwo: {
        logo: 'https://i.ytimg.com/vi/xE5SKPnPVAk/hqdefault_live.jpg',
        name: 'HXT',
      },
      game: 'csgo',
      map: 'DE_DUST 2'
    },
    {
      teamOne: {
        logo: 'https://coub-anubis-a.akamaized.net/coub_storage/channel/cw_avatar/493426a56e0/7e8149ef877242f4acbb0/profile_pic_new_2x_1609590770_11sl0c9_1474993266______.png',
        name: 'Origim',
      },
      teamTwo: {
        logo: 'https://ggstandoff.net/public/storage/items/16039911268af878579e68893ed433a4b966dc17f5.png',
        name: 'NEON',
      },
      game: 'csgo',
      map: 'DE_INFERNO'
    },
    {
      teamOne: {
        logo: 'https://hiroshimajet.files.wordpress.com/2018/02/abstract-046.png',
        name: 'MOM',
      },
      teamTwo: {
        logo: 'https://www.acrit-studio.ru/upload/iblock/df8/logo.png',
        name: 'Crssh',
      },
      game: 'rust',
    },
    {
      teamOne: {
        logo: 'https://static.tildacdn.com/tild6637-6263-4964-b861-376134656234/2.png',
        name: 'Pinks',
      },
      teamTwo: {
        logo: 'https://www.freeiconspng.com/uploads/x-png-14.png',
        name: 'REDSquad',
      },
      game: 'rust',
    },
    {
      teamOne: {
        logo: 'https://cdn130.picsart.com/247068694005212.png',
        name: 'RRRRRR',
      },
      teamTwo: {
        logo: 'https://seeklogo.com/images/A/AS-logo-2D81A516C8-seeklogo.com.png',
        name: 'Seek',
      },
      game: 'rust',
    },
    {
      teamOne: {
        logo: 'https://rtanz.co.nz/wp-content/uploads/2020/10/Z-Brand-Logos-768x857.png',
        name: 'Energy',
      },
      teamTwo: {
        logo: 'https://купить-билайт.рф/wp-content/uploads/2019/03/logo-main.png',
        name: 'BWBW',
      },
      game: 'dota',
    },
    {
      teamOne: {
        logo: 'https://ozon.com.br/wp-content/uploads/2020/05/ozon-purificador-de-ar-icone-500px.png',
        name: 'HooDS',
      },
      teamTwo: {
        logo: 'https://teamdetroitvolleyball.com/wp-content/uploads/2021/06/Adelphi.png',
        name: 'WILDS',
      },
      game: 'dota',
    },
    {
      teamOne: {
        logo: 'https://bumper-stickers.ru/38971-thickbox_default/plamja.jpg',
        name: 'FIRESTORM',
      },
      teamTwo: {
        logo: 'https://www.roaming.fi/wp-content/uploads/2015/10/roaming-logo1-585x287.png',
        name: 'Infi',
      },
      game: 'dota',
    },
    {
      teamOne: {
        logo: 'https://xenforo.com/community/data/avatars/o/143/143267.jpg?1479139089',
        name: 'FORZ',
      },
      teamTwo: {
        logo: 'https://gkiddwun4life.files.wordpress.com/2015/09/simple-freedom-peace-logo1.png',
        name: 'NSP',
      },
      game: 'tf',
    },
    {
      teamOne: {
        logo: 'https://freesvg.org/img/qubodup_Swirly_logo_thing.png',
        name: 'Vioss',
      },
      teamTwo: {
        logo: 'https://play-lh.googleusercontent.com/Dat0zJVChu66dHzG_QJWsqPi1JRtoFInBju0XBfXpCuaiGs_UqNGJwS_-AeDICd9GSY=s360',
        name: 'grreenn',
      },
      game: 'tf',
    },
    {
      teamOne: {
        logo: 'https://nuotraukos.mediakatalogas.lt/thumbs/logo-1524973_1280.png',
        name: 'Abyyyys',
      },
      teamTwo: {
        logo: 'https://4.imimg.com/data4/SX/KX/MY-20252736/official-logo-250x250.png',
        name: 'O4',
      },
      game: 'tf',
    }
  ])
  useEffect(() => {
    if(game) {
      filterMatches()
    } else {
      setMatch(match)
    }
  }, [])
  const filterMatches = () => {
    setMatch(prev => (prev.filter((el) => el.game === game)))
  }
  return(
    <Box height={'100%'} bgcolor="#12161bf7" padding={"50px"} className="main container-fluid" color={"white"} textAlign={'left'}>
      <h1 style={{ color: '#0dbcfe' }}>Matches</h1>
      <Box display='flex' justifyContent='space-between' flexWrap={'wrap'}>
        {
          match.length > 0 && match.map(el => (
            <MatchItem handleModal={handleOpen} team={el}/>
          ))
        }
      </Box>
      <Button onClick={() => handleOpen()}>See all...</Button>
      <BasicModal handleClose={handleClose} isOpen={modalOpen} />
    </Box>
  )
}